import {makeStyles} from '@material-ui/core/styles';

const useStyles=  makeStyles((theme)=>({
    navlink: {
        color: "blue",
        "& :hover, &:hover div": {
            color: "teal"
        },
        "& div": {
            color: "blue"
        }

    },
    activeNavlink: {
        color: "red",
        "& div": {
            color: "red"
        }
    },
    wrapper: {
        padding: theme.spacing(1,2,0,21),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2,2)
        },
        
    },
    table: {
        marginTop: "25px",
        padding: "23px"
    },
    dealconnect: {
        marginTop: "30px"
    },
    dealconnectPaper: {
        
    },
    feeds: {
        marginTop: "20px"
    }
}))

export default useStyles;