import React, {useEffect} from 'react'
import db from '../../../../config/firestore'
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, CircularProgress, Paper, Button, IconButton} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialTable from 'material-table'
import { collection, getDocs } from "firebase/firestore/lite"
import {useHistory, Link} from 'react-router-dom'




export default function DealShareTable() {
    const [isLoading, setIsLoading] = React.useState(true)
    const [deals, setDeals] = React.useState([])
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")
    const history = useHistory()

    const fetchDeals= async()=>{
        
         const response = collection(db, 'DealShares');
         const data= await getDocs(response);
 
         data.forEach(item=>{
             
             setDeals((prevState)=>{
               
                 return [...prevState, item.data()]
             })
            })
           setIsLoading(false)
       }

       

       useEffect(() => {
            if(currentAdmin){
                fetchDeals();
            }else{
                history.push("/")
            } 

       }, [])

       const paperStyle = {
        padding: 20,
        height: '15vh',
        width: "100%",
        margin: "20px auto"
    }  
 
    return (
        <React.Fragment>
            <Container>
                <Row style={{marginTop:"15px"}}>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :

                            deals.length == 0 ? 

                            <Grid align="center">
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography variant="h6">No Deal Share Available yet</Typography>
                                </Paper>
                            </Grid>
                            :
                            <Grid>
                                <MaterialTable
                                    title= "Devclub360 Members"
                                    columns= {[
                                        
                                        {
                                            title: 'Deal Title',
                                            field: 'DealTitle'
                                        },
                                        {
                                            title: 'Deal Description',
                                            field: 'DealDescription'
                                        },
                                        {
                                            title: 'Proposal Url',
                                            field: 'ProposalUrl',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <a href={rowData.ProposalUrl} target="_blank" style={{textDecoration: "none"}}>
                                                    view proposal
                                                </a>
                                              </>
                                        },
                                        {
                                            title: 'Numbers of Applications',
                                            field: 'NumberOfApplication'
                                        },
                                        {
                                            title: 'Numbers of Investors',
                                            field: 'NumberOfInvestors'
                                        },
                                        {
                                            title: 'Project Timeline',
                                            field: 'ProjectCompletionTime'
                                        },
                                        {
                                            title: 'View',
                                            field: 'view',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <Link to={"/dashboard/deal-share/details/"+rowData.DealConnectId} style={{textDecoration: "none"}}>
                                                    <IconButton>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Link>
                                              </>
                                        },
                                       
                                        {
                                            title: 'Delete',
                                            field: 'delete',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                
                                              </>
                                        }
                                        
                                        
                                    ]}
                                    data= {deals}
                                    
                                    options={{
                                        pageSize: 50,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
