import React, {useEffect} from 'react'
import db from '../../../../config/firestore';
import firebaseApp from '../../../../config/config';
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, CircularProgress, Paper, IconButton, TextField, Button} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MaterialTable from 'material-table'
import { collection, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore/lite"
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {useHistory} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export default function StaffsTable() {
    const [isLoading, setIsLoading] = React.useState(true)
    const [staffs, setStaffs] = React.useState([])
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")
    const history = useHistory()
    const auth = getAuth(firebaseApp)
    const [staffEmail, setStaffEmail] = React.useState("")
    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [uid, setUid] = React.useState("")
    const [staffDepartment, setDepartment] = React.useState("")

    const [open, setOpen] = React.useState(false);

    const depts = [
        {
          value: 'Communications & Strategy',
          label: 'Communications & Strategy',
        },
        {
          value: 'Business & Projects',
          label: 'Business & Projects ',
        },
        {
          value: 'Support',
          label: 'Membership & Support',
        }
      ];

    const handleClickOpen = (email, fName, lName, level, id) => {
        
        if(department ==="Support" || department ==="Super Admin"){
            setStaffEmail(email)
            setFirstName(fName)
            setLastName(lName)
            setDepartment(level)
            setUid(id)
            setOpen(true);
            
        }else{
            alert("Access Denied")
        }
        
        
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [isEditing, setIsEditing] = React.useState(false)


    const fetchStaffs= async()=>{
        
         const response = collection(db, 'Staffs');
         const data= await getDocs(response);
 
         data.forEach(item=>{
             
             setStaffs((prevState)=>{
               
                 return [...prevState, item.data()]
             })
            })
           setIsLoading(false)
       }

       

       useEffect(() => {
            if(currentAdmin){
                fetchStaffs();
            }else{
                history.push("/")
            } 

       }, [])

       const paperStyle = {
        padding: 20,
        height: '15vh',
        width: "100%",
        margin: "20px auto"
    }  
    
    const deleteStaff = async(id, name)=>{
        if(department==="Super Admin"){
            if(window.confirm("Are You sure you want to delete "+name+" Account")){
                await deleteDoc(doc(db, "Staffs", id)).then((_)=>{
                    toast.success(name+ " account has been deleted successfully")
                    window.location.reload()
                })
            }else{
                return;
            }
        }else{
            alert("Permission Denied")
        }
    }

    const changePassword = async ()=>{
        sendPasswordResetEmail(auth, staffEmail)
            .then(() => {
                toast.success("A password Reset Email has been sent to the staff")
            })
            .catch((error) => {
                toast.error(error.code)
                
        });
    }

    const editStaff = async()=>{
        if(!firstName || !lastName){
            toast.error("Some fields are empty which are required")
        }else{
            setIsEditing(true)

            await updateDoc(doc(db, "Staffs", uid), {
                firstName: firstName,
                lastName: lastName,
                Department: staffDepartment
            }).then((_)=>{
                handleClose()
                setIsEditing(false)
                toast.success("Staff Details successfully Edited")
                window.location.reload()
            })
        }
    }
 
    return (
        <React.Fragment>
            <Container>
            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                    {"Create New Staff Account"}
                    </DialogTitle>
                    <DialogContent>
                        
                        <Grid align="right">
                            <Button variant="text" color="primary" onClick={changePassword}>Change Password</Button>
                        </Grid>
                        <TextField
                            fullWidth
                            type="text"
                            value={firstName}
                            label="Staff First Name"
                            onChange={(e)=>setFirstName(e.target.value)}
                            style={{marginBottom: "10px"}}
                        />

                        <TextField
                            fullWidth
                            type="text"
                            value={lastName}
                            label="Staff Last Name"
                            onChange={(e)=>setLastName(e.target.value)}
                            style={{marginBottom: "10px"}}
                        />

                        <TextField
                            style={{marginBottom: "10px"}}
                            id="outlined-select-currency"
                            select
                            fullWidth
                            label="Select Staff Department"
                            value={staffDepartment}
                            onChange={(e)=>setDepartment(e.target.value)}
                            >
                            {depts.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                    </DialogContent>
                    <DialogActions>
                    {
                        isEditing ? <CircularProgress />
                        :
                        <Grid>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button autoFocus onClick={editStaff}>
                                Edit Account
                            </Button>
                        </Grid>
                    }
                    </DialogActions>
                </Dialog>
                <Row style={{marginTop:"15px"}}>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :

                            staffs.length === 0 ? 

                            <Grid align="center">
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography variant="h6">No Staff Available yet</Typography>
                                </Paper>
                            </Grid>
                            :
                            <Grid>
                                <MaterialTable
                                    title= "Devclub360 Staffs"
                                    columns= {[
                                        
                                        {
                                            title: 'First Name',
                                            field: 'firstName'
                                        },
                                        {
                                            title: 'Last Name',
                                            field: 'lastName'
                                        },
                                        {
                                            title: 'Email Address',
                                            field: 'AdminEmail'
                                        },
                                        {
                                            title: 'Department',
                                            field: 'Department'
                                        },
                                        {
                                            title: 'Account Status',
                                            field: 'AccountStatus'
                                        },
                                        {
                                            title: 'Delete',
                                            field: 'delete',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>deleteStaff(rowData.uid, rowData.firstName)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                
                                              </>
                                        },
                                        {
                                            title: 'Edit',
                                            field: 'edit',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>handleClickOpen(rowData.AdminEmail, rowData.firstName, rowData.lastName, rowData.Department, rowData.uid)}>
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                               
                                              </>
                                        },
                                        
                                        
                                        
                                    ]}
                                    data= {staffs}
                                    
                                    options={{
                                        pageSize: 10,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </React.Fragment>
    )
}
