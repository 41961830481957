import React from 'react'
import Header from '../component/header/Header'
import Overview from '../component/body/overview/Overview'

export default function Dashboard() {
    return (
        <React.Fragment>
            <Header />
            <Overview />
        </React.Fragment>
    )
}
