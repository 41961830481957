import React, {useState} from 'react'
import TopBar from './Topbar';
import SideBar from './SideBar';

export default function Header() {

    const [mobileOpen, setMobileOpen] = useState(false)

    

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    return (
        <div>
            <TopBar handleDrawerToggle= {handleDrawerToggle} />
            <SideBar mobileOpen={mobileOpen} handleDrawerToggle= {handleDrawerToggle} />
            
        </div>
    )
}
