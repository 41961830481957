import React from 'react'
import {Button, Grid} from '@material-ui/core';
import AddIcon  from '@material-ui/icons/Add';
import useStyles from '../../../styles/HeaderStyles';
import {useHistory} from 'react-router-dom'


export default function AddNewDeal() {
    const classes = useStyles()
    const department = localStorage.getItem("adminDepartment")
    const history = useHistory()
    const doAddNew = ()=>{
        if(department==="Communications & Strategy"){
            alert("Access Denied")
        }else{
            history.push("/dashboard/deal-share/create-new-deal")
        }
    }
    return (
        <div>
            <Grid className= {classes.dealconnect}>
                            <div style= {{textAlign: 'right'}}>
                                    <Button 
                                        variant= "contained" 
                                        color="primary"
                                        size="large"
                                        className= {classes.button}
                                        startIcon ={<AddIcon />}
                                        onClick={doAddNew}
                                        >
                                            Create New Deal
                                    </Button>
                                    
                                </div>
                                
                            </Grid>

        </div>
    )
}
