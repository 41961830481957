import React, {useEffect} from 'react'
import db from '../../../../config/firestore';
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, CircularProgress, Paper, IconButton, TextField, Button} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MaterialTable from 'material-table'
import { collection, getDocs, doc, deleteDoc, updateDoc } from "firebase/firestore/lite"
import {useHistory} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';




export default function AnnouncementTable() {
    const [isLoading, setIsLoading] = React.useState(true)
    const [announcements, setAnnouncements] = React.useState([])
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")
    const history = useHistory()

    const [open, setOpen] = React.useState(false);
    const [announcement, setAnnouncement] = React.useState("")
    const [announcementid, setAnnouncementid] = React.useState("")
    const handleClickOpen = (content, id) => {

        if(department ==="Support" || department ==="Super Admin"){
            setOpen(true)
            setAnnouncement(content)
            setAnnouncementid(id)
            
        }else{
            alert("Access Denied")
        }
       
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [isEditing, setIsEditing] = React.useState(false)


    const fetchAnnouncements= async()=>{
        
         const response = collection(db, 'Announcements');
         const data= await getDocs(response);
 
         data.forEach(item=>{
             
            setAnnouncements((prevState)=>{
               
                 return [...prevState, item.data()]
             })
            })
           setIsLoading(false)
       }

       

       useEffect(() => {
            if(currentAdmin){
                fetchAnnouncements();
            }else{
                history.push("/")
            } 

       }, [])

       const paperStyle = {
        padding: 20,
        height: '15vh',
        width: "100%",
        margin: "20px auto"
    }  

    const deleteAnnouncement = async(id)=>{
        if(department ==="Support" || department ==="Super Admin"){
            if(window.confirm("Are you sure you want to delete this announcement?")){
                await deleteDoc(doc(db, "Announcements", id)).then((_)=>{
                    toast.success("Announcement has been deleted successfully")
                })
                window.location.reload()
            }else{
                return
            }
            
        }else{
            alert("Access Denied")
        }
    }

    const editAnnouncement = async()=>{
        if(!announcement){
            toast.error("Announcement content is required")
        }else{
            setIsEditing(true)

            updateDoc(doc(db, "Announcements", announcementid),{
                Announcement: announcement
            }).then((_)=>{
                setAnnouncement("")
                handleClose()
                setIsEditing(false)
                toast.success("Announcement has been edited successfully")
                window.location.reload()


            }).catch(error=>{
                toast.error(error.code)
            })
        }
    }
 
    return (
        <React.Fragment>
            <Container>
            <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle>
                            {"Create New Announcement"}
                            </DialogTitle>
                            <DialogContent>
                                
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    value={announcement}
                                    label="Add New Announcement"
                                    onChange={(e)=>setAnnouncement(e.target.value)}
                                    style={{marginBottom: "10px"}}
                                />

                                

                            </DialogContent>
                            <DialogActions>
                            {
                                isEditing ? <CircularProgress />
                                :
                                <Grid>
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button autoFocus onClick={editAnnouncement}>
                                        Edit Announcement
                                    </Button>
                                </Grid>
                            }
                            </DialogActions>
                        </Dialog>
                <Row style={{marginTop:"15px"}}>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :

                            announcements.length == 0 ? 

                            <Grid align="center">
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography variant="h6">No Announcement Available yet</Typography>
                                </Paper>
                            </Grid>
                            :
                            <Grid>
                                <MaterialTable
                                    title= "Devclub360 Staffs"
                                    columns= {[
                                        
                                        {
                                            title: 'Announcement Date',
                                            field: 'AnnouncementDate',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                {new Date(rowData.AnnouncementDate * 1000).getDate()+
                                                    "/"+(new Date(rowData.AnnouncementDate * 1000).getMonth()+1)+
                                                    " "+new Date(rowData.AnnouncementDate * 1000).getHours()+
                                                    ":"+new Date(rowData.AnnouncementDate * 1000).getMinutes()+
                                                    ":"+new Date(rowData.AnnouncementDate * 1000).getSeconds()
                                                }
                                                
                                              </>
                                        },
                                        {
                                            title: 'Announcement Content',
                                            field: 'Announcement'
                                        },
                                        {
                                            title: 'Creator',
                                            field: 'AnnouncementMadeBy'
                                        },
                                        
                                        {
                                            title: 'Delete',
                                            field: 'delete',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>deleteAnnouncement(rowData.AnnouncementID)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                
                                              </>
                                        },
                                        {
                                            title: 'Edit',
                                            field: 'edit',
                                            render: (rowData)  => rowData && 
                                            <>
                                               
                                                    <IconButton onClick={()=>handleClickOpen(rowData.Announcement, rowData.AnnouncementID)}>
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                
                                              </>
                                        },
                                        
                                        
                                        
                                    ]}
                                    data= {announcements}
                                    
                                    options={{
                                        pageSize: 10,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </React.Fragment>
    )
}
