import React, {useEffect} from 'react'
import {useHistory, useParams, Link} from 'react-router-dom'
import { Typography, Grid, Box, Button, CircularProgress, TextField, Avatar, Divider, List, ListItem, ListItemText, IconButton } from '@mui/material'
import {Container, Row, Col} from 'react-bootstrap'
import useStyles from '../../../styles/HeaderStyles'
import SendIcon from '@mui/icons-material/Send';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ReactLoading from 'react-loading';
import db from '../../../../config/firestore'
import {getDoc, addDoc,setDoc, collection, doc, serverTimestamp, getDocs, query, where, updateDoc, deleteDoc} from 'firebase/firestore/lite'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Header from '../../header/Header'

export default function QuickPostSection() {

    const [isLoading, setIsLoading] = React.useState(true)
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")
    const history = useHistory()
    const {id} = useParams()

    const [PostedByID, setPostedByID] = React.useState("")
    const [PostedByName, setPostedByName] = React.useState("")
    const [PostedByProfilePics, setPostedByProfilePics] = React.useState("")
    const [Posts, setPosts] = React.useState("")
    const [done, setDone] = React.useState(false)
    const [comments, setComments] = React.useState([]);

    const fetchComments= async()=>{
        
    const response =collection(db, "QuickPosts", id, "Comments");
    const data= await getDocs(response);
    data.docs.forEach(item=>{
        setComments((prevState)=>{
            return [...prevState, item.data()]
        })
       
    })

    
    }

    const fetchPost = async()=>{
        const quickRef = doc(db, "QuickPosts", id);
                    await getDoc(quickRef).then(async(posts)=>{
                        if(posts.exists()){
                            setPostedByID(await posts.get("PostedByID"))
                            setPostedByName(await posts.get("PostedByName"))
                            setPostedByProfilePics(await posts.get("PostedByProfilePics"))
                            setPosts(await posts.get("Posts"))

                            fetchComments()

                            setDone(false)
                            setIsLoading(false)
                        }else{
                            setDone(false)
                            alert("Failed to fetch quick posts")
                            history.push("/dashboard/quick-posts/all")
                        }
                    })
    }
    const [fname, setFname] = React.useState("")
    const [lname, setLname] = React.useState("")
    
    const docRef = doc(db, "Members", "ddlawCUSRxSirdnLHmeBlSe3WNN2");
    useEffect(async ()=>{
        
if(currentAdmin){
    fetchPost()
        
}else{
    history.push("/")
}
  
  
},[])
const [openComment, setOpenComment] = React.useState(false)
const [comm, setComm] = React.useState("")

const [openInterest, setOpenInterest] = React.useState(false)
const [interest, setInterest] = React.useState("")

const closeOpenComment = () =>{
    setOpenComment(false)
}

const handleOpenComment = () =>{
    setOpenComment(true)
}

const closeOpenInterest = () =>{
    setOpenInterest(false)
}

const handleOpenInterest = async() =>{
    await getDoc(doc(db, "QuickPosts", id, "QuickPostInterests","ddlawCUSRxSirdnLHmeBlSe3WNN2")).then((docs)=>{
        if(docs.exists()){
            toast.info("Your Interest has already been submitted for this post",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setOpenInterest(true)
        }
    })
    
}

const addComments = async() =>{
    if(!comm){
        toast.error("Your Comment cannot be empty",{
            position: toast.POSITION.TOP_RIGHT
        })
    }else{

        await getDoc(docRef).then(async(ref)=>{
            setFname(await ref.get("firstName"))
            setLname(await ref.get("lastName"))
        }).then(async(_)=>{
            await addDoc(collection(db,"QuickPosts", id, "Comments"), {
                CommentorsID: "ddlawCUSRxSirdnLHmeBlSe3WNN2",
                CommentorsName: "Devclub360 Administrator",
                Comments: comm,
                QuickPostId: id,
                CommentDate: serverTimestamp()
              }).then((_)=>{
                  setComm("")
                  closeOpenComment()
                  setComments([])
                  fetchComments()
                  toast.success("Comment successfully added",{
                      position: toast.POSITION.TOP_RIGHT
                  })
              }).catch((error)=>{
                setComm("")
                closeOpenComment()
                
                toast.error("Failed to post comment, please try later",{
                    position: toast.POSITION.TOP_RIGHT
                })
              })
        })
        
    }
}

const addInterests = async()=>{
    if(!interest){
        toast.error("Please indicate your offer to this post",{
            position: toast.POSITION.TOP_RIGHT
        })
    }else{
        await getDoc(doc(db, "QuickPosts", id, "QuickPostInterests", "ddlawCUSRxSirdnLHmeBlSe3WNN2")).then(async(docs)=>{
            if(docs.exists()){
                toast.info("Your Interest has already been submitted for this post",{
                    position: toast.POSITION.TOP_RIGHT
                })
                setInterest("")
                      closeOpenInterest()
            }else{
    
                await getDoc(docRef).then(async(docs)=>{
                    setFname(await docs.get("firstName"))
                    setLname(await docs.get("lastName"))
                }).then(async (_)=>{
                    await setDoc(doc(db,"QuickPosts", id, "QuickPostInterests", "ddlawCUSRxSirdnLHmeBlSe3WNN2"), {
                        "MemberID": "ddlawCUSRxSirdnLHmeBlSe3WNN2",
                        "MembersName": "Devclub360 Administrator",
                        "InterestOffer": interest,
                        "QuickPostId": id,
                        "CreatedDate": serverTimestamp()
        
                    }).then((_)=>{
                        setInterest("")
                      closeOpenInterest()
                        toast.success("Interest successfully added",{
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }).catch((error)=>{
                      setInterest("")
                      closeOpenInterest()
                      
                      toast.error("Failed to post Interest, please try later",{
                          position: toast.POSITION.TOP_RIGHT
                      })
                    })
                })
                
            }
        })
    }
}

const classes = useStyles()

const paperStyle = {
    padding: 20,
    width: "100%",
    height: "28vh",
    margin: "0px auto"
}

const [editComment, setEditComment] = React.useState("")
const [openEditComment, setOpenEditComment] = React.useState(false)
const [postid,setPostID] = React.useState("")

const closeEditComment = () =>{
    setOpenEditComment(false)
}

const handleEditOpen = async(comment, uid) =>{

    if(department ==="Communications & Strategy"){
        alert("Access Denied")
    }else{

        if(uid==="" || uid===undefined){
            const q = query(collection(db, "QuickPosts", id, "Comments"), where("Comments", "==", comment));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((data) => {
                
                    updateDoc(doc(db, "QuickPosts", id,"Comments", data.id),{
                        CommentId: data.id
                    }).then((_)=>{
                        setEditComment(comment)
                        setOpenEditComment(true)
                        setPostID(data.id)
                    })
                
            });
        }else{
            setEditComment(comment)
            setOpenEditComment(true)
            setPostID(uid)
        }
        
    }
    
}

const updateComments = async()=>{
    if(!editComment){
        toast.error("Comment cannot be empty")
    }else{
        if(window.confirm("Are you sure you want to edit this comment")){
            await updateDoc(doc(db, "QuickPosts", id,"Comments", postid),{
                Comments: editComment
            }).then((_)=>{
                closeEditComment()
                toast.success("Comment successfully edited")
                setComments([])
                fetchComments()

            })
        }else{
            closeEditComment()
    
        }
    }

}

const deleteComment = async(comment, uid)=>{
    if(uid==="" || uid===undefined){
        const q = query(collection(db, "QuickPosts", id, "Comments"), where("Comments", "==", comment));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((data) => {
            
                updateDoc(doc(db, "QuickPosts", id,"Comments", data.id),{
                    CommentId: data.id
                }).then(async(_)=>{
                    if(department==="Communications & Strategy"){
                        alert("Access denied")
                    }else{
                        if(window.confirm("Are you sure you want to delete this comment")){
                           await deleteDoc(doc(db,"QuickPosts", id,"Comments", data.id)).then((_)=>{
                                toast.success("Comment successfully deleted")
                                setComments([])
                                fetchComments()

                            })
                        }else{
                            return
                        }
                    }
                })
            
        });
    }else{
        if(department==="Communications & Strategy"){
            alert("Access denied")
        }else{
            if(window.confirm("Are you sure you want to delete this comment")){
               await deleteDoc(doc(db,"QuickPosts", id,"Comments", uid)).then((_)=>{
                    toast.success("Comment successfully deleted")
                    setComments([])
                    fetchComments()

                })
            }else{
                return
            }
        }
    }
}

    return (
        <React.Fragment>
            <Header />
            <Container className={classes.wrapper}>

                {
                    done ?
                <Grid align="center">
                    <ReactLoading type={"spin"} color={"green"} height={50} width={50} />
                </Grid>
                    :
                    isLoading ? <CircularProgress /> :
                    <Grid>
                        <Dialog open={openEditComment} onClose={closeEditComment}>
                  <DialogTitle>Edit Comment</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    Edit the comment here
                    </DialogContentText>

                    <TextField
                      
                      id="comment"
                      label="Your Comment"
                      fullWidth
                      multiline
                      rows={5}
                      value={editComment}
                      onChange={(e)=>setEditComment(e.target.value)}
                    />
                    
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={closeEditComment}>Cancel</Button>
                  <Button endIcon={<SendIcon />} onClick={()=>updateComments()}>Edit Comment</Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={openComment} onClose={closeOpenComment}>
                  <DialogTitle>Your Comment</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    Fill in your comment here
                    </DialogContentText>

                    <TextField
                      autoFocus
                      margin="dense"
                      id="comment"
                      label="Your Comment"
                      fullWidth
                      value={comm}
                      onChange={(e)=>setComm(e.target.value)}
                      variant="standard"
                    />
                    
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={closeOpenComment}>Cancel</Button>
                  <Button endIcon={<SendIcon />} onClick={()=>addComments()}>Submit Comment</Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openInterest} onClose={closeOpenInterest}>
                  <DialogTitle>Your Offer to this Post</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    Fill in your Offer here
                    </DialogContentText>

                    <TextField
                      autoFocus
                      margin="dense"
                      id="offer"
                      label="Your Offer for this post"
                      fullWidth
                      value={interest}
                      onChange={(e)=>setInterest(e.target.value)}
                      variant="standard"
                    />
                    
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={closeOpenInterest}>Cancel</Button>
                  <Button endIcon={<SendIcon />} onClick={()=>addInterests()}>Submit Interest</Button>
                  </DialogActions>
                </Dialog>

                        <Box component="Paper">
                            <Container>
                                <Row>
                                    <Col xs={4} md={4} xl={4} lg={4} sm={4}>
                                      <Link to={"/dashboard/members/details/"+PostedByID} style={{textDecoration: "none"}}><Avatar src={PostedByProfilePics} alt ="profile" sx={{ width: 80, height: 80 }} /></Link>
                                    </Col>
                                    <Col  xs={8} md={8} xl={8} lg={8} sm={8}><Typography variant="inherit">{Posts}</Typography></Col>
                                </Row>
                                <Row align="center" style={{marginTop: "11px"}}> 
                                    <Col><Button variant="text" onClick={handleOpenComment}>Comment</Button></Col>
                                    <Col><Button variant="text" onClick={handleOpenInterest}>Show Interest</Button></Col>
                                </Row>
                                
                            </Container>
                        </Box>
                        <Divider />

                        <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', }} align="right">
                        {
                            comments.length !=0?
                        comments && comments.map((comment, index)=>(
                            <Grid align="right">
                                <ListItem key={index} 
                                    secondaryAction={
                                            <Container>
                                                
                                                <Link target="_blank" style={{textDecoration: "none"}} to={"/dashboard/members/details/"+comment.CommentorsID}>
                                                    <PersonIcon />
                                                </Link>
                                            
                                            
                                                <IconButton edge="end" arial-label="edit" onClick={()=>handleEditOpen(comment.Comments, comment.CommentId)}>
                                                    <EditOutlinedIcon />
                                                </IconButton>
                                        

                                            
                                                <IconButton edge="end" aria-label="delete" onClick={()=>deleteComment(comment.Comments, comment.CommentId)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Container>
                                      }
                                >
                                
                                <ListItemText align="right" primary={comment.Comments} secondary={comment.CommentorsID==currentAdmin.user.uid?"Devclub Administrator": comment.CommentorsName} />
                                    <Divider />
                                </ListItem>
                            </Grid>
                        ))
                            
                        
                        :
                        <Grid align="center">
                                <Box>
                                    <Typography variant="inherit" align="right">No Comments available yet</Typography>
                                </Box>
                         </Grid>
                    }
                    </List>
                    
                    </Grid>
                }
                <ToastContainer />
            </Container>
        </React.Fragment>
    )
}
