import React,{useEffect} from 'react'
import useStyles from '../styles/HeaderStyles'
import { Container, Row, Col } from 'react-bootstrap'
import {Grid, Typography, Divider, CircularProgress, IconButton, Button, TextField} from '@mui/material'
import Header from '../component/header/Header'
import CreateBroadcast from '../component/body/messages/CreateBroadcast'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MaterialTable from 'material-table'
import {useHistory, Link} from 'react-router-dom'
import db from '../../config/firestore'
import {collection, getDocs,  addDoc, serverTimestamp, setDoc, doc, getDoc} from 'firebase/firestore/lite'
import ReplyIcon from '@mui/icons-material/Reply';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


export default function Messages() {
    const classes = useStyles()

    const [value, setValue] = React.useState(0);
    const [inboxes, setInboxes] = React.useState([])
    const [sents, setSents] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [uid, setUid] = React.useState("")
    const [isSending, setSending] = React.useState(false)
    const [subject, setSubject] = React.useState("")
    const [message, setMessage] = React.useState("")
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (id) => {
        
            setOpen(true);
            setUid(id)
       
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fetchInboxes= async()=>{
        
        const response = collection(db, 'AdminInbox');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setInboxes((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
           setIsLoading(false)
      }

      const fetchSents= async()=>{
        
        const response = collection(db, 'AdminSentMessages');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setSents((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })

           fetchInboxes()
           

          
      }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const history = useHistory()
  const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))

  useEffect(() => {
     if(currentAdmin){
         fetchSents()
     }else{
         history.push("/")
     }
  }, [])

  const doMessage = async() =>{
    if(!message || !subject){
        toast.error("Message Subject and Message Content is required",{
            position: toast.POSITION.TOP_RIGHT
        })
    }else{
        setSending(true)

        await addDoc(collection(db, "MembersInbox"), {
            MessageContent: message,
            MessageCreatedAt: serverTimestamp(),
            MessageReceiver: uid,
            MessageSenderID: "Devclub360 Admin",
            MessageSubject: subject
          }).then(async(msg)=>{
                
                await setDoc(doc(db, 'AdminSentMessages', msg.id),{
                    MessageContent: message,
                    MessageCreatedAt: serverTimestamp(),
                    MessageReceiver: uid,
                    MessageSenderID: "Devclub360 Admin",
                    MessageSubject: subject
                }).then(async(_)=>{
                    await getDoc(doc(db, "Members",uid)).then(async(data)=>{
                        const emailAdd = await data.get("EmailAddress")

                       await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                        method: "post",
                        headers: {
                        "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                        name: "Devcluber",
                        email: emailAdd,
                        subject: subject,
                        message: "<p>"+message+"</p>",
                        adminEmail: "operations@devclub360.com",
                        adminSubject: subject,
                        adminMessage: "<p>"+message+ " has been sent to "+emailAdd+"</p>"
                        })
                    }).then(res=>res.json())
                    .then(data=>{
                        console.log(data)
                    })

                    setMessage("")
                    setSubject("")
                    handleClose()
                    toast.success("Message has been successfully delivered",{
                        position: toast.POSITION.TOP_RIGHT
                    })
                })

                    
                }).catch((error)=>{
                toast.error(error.code,{
                    position: toast.POSITION.TOP_RIGHT
                })
            })
          }).catch((error)=>{
            toast.error(error.code,{
                position: toast.POSITION.TOP_RIGHT
            })
          })
    }
}
    return (
        <React.Fragment>
            <Header />
            <Container className={classes.wrapper}>

            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Send Message to this Member"}
                    </DialogTitle>
                    <DialogContent>
                    <TextField
                        fullWidth
                        name="subject"
                        placeholder= "Message Subject"
                        label="Message Subject"
                        type="text"
                        value={subject}
                        onChange={(e)=>setSubject(e.target.value)}
                        style={{marginBottom:"15px"}}
                    />

                    <TextField
                        fullWidth
                        name="content"
                        placeholder= "Message Content"
                        label="Message Content"
                        multiline
                        rows={5}
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        
                    />

                    </DialogContent>
                    <DialogActions>
                        {
                            isSending ? null : <Button onClick={handleClose}>Cancel</Button>
                        }
                    
                    {
                        isSending ? <CircularProgress />
                        :
                        <Button autoFocus onClick={doMessage}>
                        Send Message
                    </Button>
                    }
                    </DialogActions>
                </Dialog>
                <Row>
                    <Col>
                        <Grid>
                            <Typography variant="h4">Messages</Typography>
                            <Divider style={{width: "100%"}} />
                        </Grid>
                    </Col>
                </Row>
                <Row>
                    <Col><CreateBroadcast /></Col>
                </Row>

                <Row style={{marginTop: "20px"}}>
                   {
                       isLoading ? 
                       <Grid align="center">
                           <CircularProgress />
                       </Grid>
                       :
                       <Box sx={{ width: '100%' }}>
                       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                           <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                           <Tab label="Admin Inbox" {...a11yProps(0)} />
                           <Tab label="Admin Sent Messages" {...a11yProps(1)} />
                           </Tabs>
                       </Box>
                       <TabPanel value={value} index={0}>
                           {
                               inboxes.length=== 0 ?
                               <Typography align="center" variant="h5">No Inbox available</Typography>
                               :

                               <Grid>
                                <MaterialTable
                                    title= "Devclub360 Admin inbox"
                                    columns= {[
                                        
                                        {
                                            title: 'Sender',
                                            field: 'MessageSenderID',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <Link to={"/dashboard/members/details/"+rowData.MessageSenderID} style={{textDecoration: "none"}} target="_blank">
                                                    <Typography>View Sender's Profile</Typography>
                                                </Link>
                                              </>
                                        },
                                        {
                                            title: 'Message Subject',
                                            field: 'MessageSubject'
                                        },
                                        {
                                            title: 'Message Content',
                                            field: 'MessageContent'
                                        },
                                        {
                                            title: "Date",
                                            field: 'MessageCreatedAt',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                {new Date(rowData.MessageCreatedAt * 1000).getDate()+
                                                    "/"+(new Date(rowData.MessageCreatedAt * 1000).getMonth()+1)+
                                                    " "+new Date(rowData.MessageCreatedAt * 1000).getHours()+
                                                    ":"+new Date(rowData.MessageCreatedAt * 1000).getMinutes()+
                                                    ":"+new Date(rowData.MessageCreatedAt * 1000).getSeconds()
                                                }
                                                
                                              </>
                                        },
                                        
                                        {
                                            title: 'Reply',
                                            field: 'reply',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>handleClickOpen(rowData.MessageSenderID)}>
                                                        <ReplyIcon />
                                                    </IconButton>
                                                
                                              </>
                                        },
                                        
                                    ]}
                                    data= {inboxes}
                                    
                                    options={{
                                        pageSize: 20,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>

                           }
                       </TabPanel>
                       <TabPanel value={value} index={1}>
                       {
                               sents.length=== 0 ?
                               <Typography align="center" variant="h5">No Sent Messages available</Typography>
                               :

                               <Grid>
                                <MaterialTable
                                    title= "Devclub360 Sent Messages"
                                    columns= {[
                                        
                                        {
                                            title: 'Receiver',
                                            field: 'MessageReceiver',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <Link to={"/dashboard/members/details/"+rowData.MessageReceiver} style={{textDecoration: "none"}} target="_blank">
                                                    <Typography>View Receiver's Profile</Typography>
                                                </Link>
                                              </>
                                        },
                                        {
                                            title: 'Message Subject',
                                            field: 'MessageSubject'
                                        },
                                        {
                                            title: 'Message Content',
                                            field: 'MessageContent'
                                        },
                                        {
                                            title: "Date",
                                            field: 'MessageCreatedAt',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                {new Date(rowData.MessageCreatedAt * 1000).getDate()+
                                                    "/"+(new Date(rowData.MessageCreatedAt * 1000).getMonth()+1)+
                                                    " "+new Date(rowData.MessageCreatedAt * 1000).getHours()+
                                                    ":"+new Date(rowData.MessageCreatedAt * 1000).getMinutes()+
                                                    ":"+new Date(rowData.MessageCreatedAt * 1000).getSeconds()
                                                }
                                                
                                              </>
                                        },
                                        
                                        {
                                            title: 'Send Another Message',
                                            field: 'reply',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>handleClickOpen(rowData.MessageReceiver)}>
                                                        <ReplyIcon />
                                                    </IconButton>
                                                
                                              </>
                                        },
                                        
                                    ]}
                                    data= {sents}
                                    
                                    options={{
                                        pageSize: 20,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>

                           }
                       </TabPanel>
                       
                   </Box>
                   }
                </Row>
                   <ToastContainer />
            </Container>
        </React.Fragment>
    )
}
