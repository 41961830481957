import React from 'react'
import useStyles from '../styles/HeaderStyles'
import { Container, Row, Col } from 'react-bootstrap'
import {Grid, Typography, Divider} from '@mui/material'
import AddNewTransaction from '../component/body/transactions/AddNewTransaction'
import Header from '../component/header/Header'
import TransactionTable from '../component/body/transactions/TransactionTable'

export default function Transactions() {
    const classes = useStyles()
    return (
        <React.Fragment>
            <Header />
            <Container className={classes.wrapper}>
                <Row>
                    <Col>
                        <Grid>
                            <Typography variant="h4">Transactions</Typography>
                            <Divider style={{width: "100%"}} />
                        </Grid>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AddNewTransaction />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TransactionTable />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
