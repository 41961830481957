import React, {useEffect} from 'react'
import db from '../../../../config/firestore';
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, CircularProgress, Paper, IconButton} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MaterialTable from 'material-table'
import { collection, getDocs } from "firebase/firestore/lite"
import {useHistory, Link} from 'react-router-dom'




export default function TransactionTable() {
    const [isLoading, setIsLoading] = React.useState(true)
    const [transactions, setTransactions] = React.useState([])
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const history = useHistory()

    const fetchTransactions= async()=>{
        
         const response = collection(db, 'MembersTransactions');
         const data= await getDocs(response);
 
         data.forEach(item=>{
             
             setTransactions((prevState)=>{
               
                 return [...prevState, item.data()]
             })
            })
           setIsLoading(false)
       }

       

       useEffect(() => {
            if(currentAdmin){
                fetchTransactions();
            }else{
                history.push("/")
            } 

       }, [])

       const paperStyle = {
        padding: 20,
        height: '15vh',
        width: "100%",
        margin: "20px auto"
    }  
 
    return (
        <React.Fragment>
            <Container>
                <Row style={{marginTop:"15px"}}>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :

                            transactions.length == 0 ? 

                            <Grid align="center">
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography variant="h6">No Transaction Available yet</Typography>
                                </Paper>
                            </Grid>
                            :
                            <Grid>
                                <MaterialTable
                                    title= "Devclub360 Transactions"
                                    columns= {[
                                        
                                        {
                                            title: 'Transaction Amount',
                                            field: 'TransactionAmount'
                                        },
                                        {
                                            title: 'Transaction Location',
                                            field: 'TransactionLocation'
                                        },
                                        {
                                            title: 'Size of Project',
                                            field: 'SizeOfProject'
                                        },
                                        {
                                            title: 'View',
                                            field: 'view',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <Link to={"/dashboard/staffs/details/"+rowData.TransactionID} style={{textDecoration: "none"}}>
                                                    <IconButton>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Link>
                                              </>
                                        },
                                        {
                                            title: 'Edit',
                                            field: 'edit',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <Link to={"/dashboard/staffs/edit/"+rowData.TransactionID} style={{textDecoration: "none"}}>
                                                    <IconButton>
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                </Link>
                                              </>
                                        },
                                        {
                                            title: 'Delete',
                                            field: 'delete',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <Link to={"/dashboard/staffs/delete/"+rowData.TransactionID} style={{textDecoration: "none"}}>
                                                    <IconButton>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Link>
                                              </>
                                        },
                                        
                                        
                                        
                                        
                                    ]}
                                    data= {transactions}
                                    
                                    options={{
                                        pageSize: 20,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
