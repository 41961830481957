import { initializeApp } from 'firebase/app';

var firebaseConfig = {
  apiKey: "AIzaSyA3P35AFiNDUskEN9mb39Bk2fwdY_V9LRs",
  authDomain: "devclub360-371df.firebaseapp.com",
  projectId: "devclub360-371df",
  storageBucket: "devclub360-371df.appspot.com",
  messagingSenderId: "1063179265805",
  appId: "1:1063179265805:web:9bc83f7a59eeead930f244",
  measurementId: "G-GK0L026FVE"
  };

  const firebaseApp= initializeApp(firebaseConfig);
export default firebaseApp;