import React from 'react'
import {Container, Col, Row} from 'react-bootstrap'
import {Avatar, Grid, Paper, TextField, Button, Typography} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Link} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebaseApp from '../config/config'
import db from '../config/firestore'
import {doc, getDoc } from "firebase/firestore/lite";
import {useHistory} from 'react-router-dom'

export default function Login() {

    const paperStyle = {
        padding: 20,
        height: '60vh',
        width: 300,
        margin: "20px auto"
    }

    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }

    const [isLoading, setIsLoading] = React.useState(false);
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")

    const auth = getAuth(firebaseApp)

    const history = useHistory()

    const adminSignIn = async() =>{
        if(!email){
            toast.error("Your Admin Email is required",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else if(!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)){
            toast.error("Your Admin Email is not a valid one",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else if(!password){
            toast.error("Your Admin Password is required",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setIsLoading(true)
            signInWithEmailAndPassword(auth, email, password).then(async(adminCredential)=>{
                const docRef = doc(db, "Staffs", adminCredential.user.uid);
                await getDoc(docRef).then(async(docs)=>{
                    if(docs.exists()){
                        //set localStorage and login to dashboard

                        const accountStatus = await docs.get("AccountStatus")

                        if(accountStatus !=="Active"){
                            toast.info("Your Admin Account has been suspended",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false)
                        }else{
                            localStorage.setItem("currentAdmin", JSON.stringify(adminCredential))
                            localStorage.setItem("adminFirstName", await docs.get("firstName"))
                            localStorage.setItem("adminLastName", await docs.get("lastName"))
                            localStorage.setItem("adminDepartment", await docs.get("Department"))
                            localStorage.setItem("adminAccountStatus", await docs.get("AccountStatus"))

                            setIsLoading(false)
                            //redirect to dashboard

                            history.push("/dashboard/dashboard")

                        }
                                                
                    }else{
                        toast.error("Staff record does not exist",{
                            position: toast.POSITION.TOP_RIGHT
                        })
                        setIsLoading(false)
                    }
                }).catch((error)=>{
                    toast.error(error.code,{
                        position: toast.POSITION.TOP_RIGHT
                    })

                    

                    setIsLoading(false)
                })
            }).catch((error)=>{
                toast.error(error.code,{
                    position: toast.POSITION.TOP_RIGHT
                })
                setIsLoading(false)
                
            })
        }

        
    }

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <Grid>
                            <Paper elevation={10} style={paperStyle}>
                                <Grid align ="center">
                                    <Avatar style= {avatarStyle}><LockOutlinedIcon /></Avatar>
                                    <h2>Admin Sign In</h2>
                                </Grid>

                                <TextField 
                                    id="email" 
                                    label="Email" type="email"
                                    value={email}
                                    onChange = {(e)=>setEmail(e.target.value)} 
                                    required 
                                    placeholder = "Enter valid email" 
                                    variant="standard" 
                                    fullWidth 
                                />
                                <TextField 
                                    id="password" 
                                    label="Password" 
                                    required 
                                    placeholder = "Enter Password" 
                                    value={password}
                                    onChange= {(e)=>setPassword(e.target.value)}
                                    type="password" 
                                    variant="standard" 
                                    fullWidth 
                                />
                                {
                                    isLoading ? 
                                    <Grid align="center" style={{marginTop:"10px"}}>
                                        <ReactLoading type={"spin"} color={"green"} height={50} width={50} />
                                    </Grid>
                                    :
                                    <Button     
                                    style={{marginTop:"10px"}} 
                                    color= "success" 
                                    fullWidth 
                                    variant="contained"
                                    onClick={()=>adminSignIn()}
                                    >
                                        Sign In
                                </Button>
                                }
                                <Typography align="center" style={{paddingTop: "10px", paddingBottom: "10px"}}>
                                    <Link to="/forgot-password"> 
                                        Forgot Password?
                                    </Link>
                                </Typography>
                            </Paper>
                            <ToastContainer />
                        </Grid>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
