import React, {useEffect} from 'react'
import db from '../../../../config/firestore'
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, CircularProgress, Paper, Button, IconButton} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialTable from 'material-table'
import { collection, getDocs, query, where, updateDoc, deleteDoc, doc } from "firebase/firestore/lite"
import {useHistory, Link} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export default function DealConnectTable() {
    const [isLoading, setIsLoading] = React.useState(true)
    const [deals, setDeals] = React.useState([])
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")
    const history = useHistory()

    const fetchDeals= async()=>{
        
         const response = collection(db, 'DealConnects');
         const data= await getDocs(response);
 
         data.forEach(item=>{
             
             setDeals((prevState)=>{
               
                 return [...prevState, item.data()]
             })
            })
           setIsLoading(false)
       }

       

       useEffect(() => {
            if(currentAdmin){
                fetchDeals();
            }else{
                history.push("/")
            } 

       }, [])

       const paperStyle = {
        padding: 20,
        height: '15vh',
        width: "100%",
        margin: "20px auto"
    }  

    const viewDeal = async(id, title)=>{
        if(id==="" || id===undefined){
            const q = query(collection(db, "DealConnects"), where("DealTitle", "==", title));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((data) => {
                
                    updateDoc(doc(db, "DealConnects", data.id),{
                        DealConnectId: data.id
                    }).then((_)=>{
                        history.push("/dashboard/deal-connect/details/"+data.id)
                    })
                
            });
        }else{
            history.push("/dashboard/deal-connect/details/"+id)
        }
    }

    const deleteDeal = async(id, title)=>{
        if(department==="Communications & Strategy"){
            alert("Access Denied")
        }else{
            if(id==="" || id===undefined){
                const q = query(collection(db, "DealConnects"), where("DealTitle", "==", title));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((data) => {
                    
                       if(window.confirm("Are you sure you want to delete this deal")){
                            deleteDoc(doc(db, "DealConnects", data.id)).then((_)=>{
        
                                
                                toast.success(title+" successfully deleted")
                                window.location.reload()
        
                            })
                       }else{
                           return
                       }
                    
                });
            }else{
                if(window.confirm("Are you sure you want to delete this deal")){
                    await deleteDoc(doc(db, "DealConnects", id)).then((_)=>{
    
                            
                        toast.success(title+" successfully deleted")
                        window.location.reload()
        
                    })
                }else{
                    return
                }
            }
        }
    }
 
    return (
        <React.Fragment>
            <Container>
                <Row style={{marginTop:"15px"}}>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :

                            deals.length == 0 ? 

                            <Grid align="center">
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography variant="h6">No Deal Connect Available yet</Typography>
                                </Paper>
                            </Grid>
                            :
                            <Grid>
                                <MaterialTable
                                    title= "Devclub360 Deal Connect"
                                    columns= {[
                                        {
                                            title: 'Deal creator picture',
                                            field: 'DealCreatorPics',
                                            render: rowData => (
                                                <img
                                                  src={rowData.DealCreatorPics}
                                                  alt="Profile Picture"
                                                  style={{ width: 40, borderRadius: "50%" }}
                                                />
                                              )
                                        },
                                        {
                                            title: 'Deal Creator',
                                            field: 'DealCreatorName'
                                        },
                                        {
                                            title: 'Deal Title',
                                            field: 'DealTitle'
                                        },
                                        {
                                            title: 'Deal Creator',
                                            field: 'DealCreatorName'
                                        },
                                        {
                                            title: 'Numbers of Partners Required',
                                            field: 'NumberOfInvestors'
                                        },
                                        {
                                            title: 'Project Timeline',
                                            field: 'ProjectCompletionTimeLine'
                                        },
                                        {
                                            title: 'Date of Creation',
                                            field: 'createdAt',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                {new Date(rowData.createdAt * 1000).getDate()+
                                                    "/"+(new Date(rowData.createdAt * 1000).getMonth()+1)+
                                                    " "+new Date(rowData.createdAt * 1000).getHours()+
                                                    ":"+new Date(rowData.createdAt * 1000).getMinutes()+
                                                    ":"+new Date(rowData.createdAt * 1000).getSeconds()
                                                }
                                                
                                              </>
                                        },
                                        {
                                            title: 'View',
                                            field: 'view',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>viewDeal(rowData.DealConnectId, rowData.DealTitle)}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                
                                              </>
                                        },
                                        
                                        {
                                            title: 'Delete',
                                            field: 'delete',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>deleteDeal(rowData.DealConnectId, rowData.DealTitle)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                
                                              </>
                                        }
                                        
                                        
                                    ]}
                                    data= {deals}
                                    
                                    options={{
                                        pageSize: 50,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </React.Fragment>
    )
}
