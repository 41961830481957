import React, {useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';//Transaction
import RssFeedIcon from '@mui/icons-material/RssFeed'; // Feeds
import MailOutlineIcon from '@mui/icons-material/MailOutline'; //Messages
import PostAddIcon from '@mui/icons-material/PostAdd'; // Quick post
import CardMembershipIcon from '@mui/icons-material/CardMembership'; // Membership license
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CategoryIcon from '@mui/icons-material/Category'; //Category
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useStyles from '../../styles/HeaderStyles'
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import db from '../../../config/firestore'
import {getDocs, collection, query, where,} from 'firebase/firestore/lite'

export default function SideNav() {

    const [feeds, setFeeds] = React.useState([])
    const [connect, setConnect] = React.useState([])
    const [share, setShare] = React.useState([])
    const [inbox, setInbox] = React.useState([])
    const [notification, setNotification] = React.useState([])
    const [transaction, setTransaction] = React.useState([])

    
const classes = useStyles()
    const listItemData = [
        {label: "Overview", link:"/dashboard/dashboard", icon: <HomeIcon /> },
        {label: "Members", link:"/dashboard/members/all", icon: <PersonIcon /> },
        {label: "Staffs", link:"/dashboard/staffs/all", icon: <PeopleAltIcon /> },
        {
            label: "Feeds", 
            link:"/dashboard/feeds",
            icon:  
            <Stack spacing={2} direction="row">
                <Badge badgeContent={feeds.length} color="secondary">
                    <RssFeedIcon />
                </Badge>
            </Stack>  
        },
        {
            label: "Announcements",
            link: "/dashboard/announcements", 
            icon: 
            <Stack spacing={2} direction="row">
                <Badge badgeContent={notification.length} color="secondary">
                <NotificationsActiveIcon />
                </Badge>
            </Stack>  
            
            
        },
        {
            label: "Deal Connect", 
            link:"/dashboard/deal-connect/all", 
            icon: 
            <Stack spacing={2} direction="row">
                <Badge badgeContent={connect.length} color="secondary">
                    <AddBusinessIcon /> 
                </Badge>
            </Stack>  
            
            
        },
        {
            label: "Deal Share", 
            link:"/dashboard/deal-share/all", 
            icon: 

            <Stack spacing={2} direction="row">
                <Badge badgeContent={share.length} color="secondary">
                    <BusinessIcon /> 
                </Badge>
            </Stack>  
            
             
        },
        {
            label: "Transactions", 
            link:"/dashboard/transactions", 
            icon: 
            <Stack spacing={2} direction="row">
                <Badge badgeContent={transaction.length} color="secondary">
                    <AccountBalanceWalletIcon /> 
                </Badge>
            </Stack> 
             
        },
        {label: "Payments", link:"/dasboard/payments", icon: <CardMembershipIcon /> },
        {label: "Support Tickets", link:"/members/category", icon: <CategoryIcon /> },
        {
            label: "Messages", 
            link:"/dashboard/messages", 
            icon: 
            <Stack spacing={2} direction="row">
                <Badge badgeContent={inbox.length} color="secondary">
                    <MailOutlineIcon /> 
                </Badge>
            </Stack> 
            
             
        },
        {label: "Quick Posts", link:"/dashboard/quick-posts/all", icon: <PostAddIcon /> },
        
    ]
    return (
        <React.Fragment>
            <Grid>
                <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        
                </Typography>
            </Grid>
            <List>
                {
                    listItemData.map((item, index)=>(
                        <ListItem component={NavLink} to={item.link} className={classes.navlink} activeClassName={classes.activeNavlink} key= {index}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText>{item.label}</ListItemText>
                        </ListItem>
                    ))
                }
                
            </List>
        </React.Fragment>
    )
}
