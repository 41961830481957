import React from 'react'
import {Button, Grid, TextField, CircularProgress} from '@mui/material';
import AddIcon  from '@material-ui/icons/Add';
import useStyles from '../../../styles/HeaderStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import db from '../../../../config/firestore';
import {addDoc, updateDoc, doc, serverTimestamp, collection} from 'firebase/firestore/lite'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function AddAnnouncementButton() {
    const classes = useStyles()

    const [open, setOpen] = React.useState(false);
    const [announcement, setAnnouncement] = React.useState("")
    const handleClickOpen = () => {
       setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [isLoading, setIsLoading] = React.useState(false)

    const createAnnouncement = async()=>{
        if(!announcement){
            toast.error("Announcement is required")
        }else{
            setIsLoading(true)

             await addDoc(collection(db, "Announcements"), {
                Announcement: announcement,
                AnnouncementDate: serverTimestamp(),
                AnnouncementMadeBy: localStorage.getItem("adminFirstName")
              }).then(async(update)=>{
                    updateDoc(doc(db, "Announcements", update.id),{
                        AnnouncementID: update.id
                    }).then((_)=>{
                        setAnnouncement("")
                        handleClose()
                        setIsLoading(false)
                        toast.success("Announcement has been created successfully")
                        window.location.reload()


                    })
              })


        }
    }

    return (
        <div>
            <Grid className= {classes.dealconnect}>
                    <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle>
                            {"Create New Announcement"}
                            </DialogTitle>
                            <DialogContent>
                                
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    value={announcement}
                                    label="Add New Announcement"
                                    onChange={(e)=>setAnnouncement(e.target.value)}
                                    style={{marginBottom: "10px"}}
                                />

                                

                            </DialogContent>
                            <DialogActions>
                            {
                                isLoading ? <CircularProgress />
                                :
                                <Grid>
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button autoFocus onClick={createAnnouncement}>
                                        Create Announcement
                                    </Button>
                                </Grid>
                            }
                            </DialogActions>
                        </Dialog>
                            <div style= {{textAlign: 'right'}}>
                                    <Button 
                                        variant= "contained" 
                                        color="primary"
                                        size="large"
                                        className= {classes.button}
                                        startIcon ={<AddIcon />}
                                        onClick={handleClickOpen}
                                        >
                                            Create New Announcement
                                    </Button>
                                    
                                </div>
                                
                            </Grid>
                            <ToastContainer />
        </div>
    )
}
