import React, {useEffect} from 'react'
import db from '../../../../config/firestore'
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, CircularProgress, Paper, IconButton, TextField, Button} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import MaterialTable from 'material-table'
import { collection, getDocs, addDoc, serverTimestamp, setDoc, doc } from "firebase/firestore/lite"
import {useHistory, Link} from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export default function MembersTable() {
    const [isLoading, setIsLoading] = React.useState(true)
    const [members, setMembers] = React.useState([])
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")
    const history = useHistory()

    const fetchMembers= async()=>{
        
         const response = collection(db, 'Members');
         const data= await getDocs(response);
 
         data.forEach(item=>{
             
             setMembers((prevState)=>{
               
                 return [...prevState, item.data()]
             })
            })
           setIsLoading(false)
       }

       

       useEffect(() => {
            if(currentAdmin){
                fetchMembers();
            }else{
                history.push("/")
            } 

       }, [])

       const paperStyle = {
        padding: 20,
        height: '15vh',
        width: "100%",
        margin: "20px auto"
    }  

    const doEdit = (id)=> {
        
        if(department ==="Support" || department === "Super Admin"){
            history.push("/dashboard/members/details/edit/"+id)
            
            
            
        }else{
            alert("Permission Denied... You are not allowed to carry out this process")
        }
    }

    const [open, setOpen] = React.useState(false);
    const [uid, setUid] = React.useState("")
    const [isSending, setSending] = React.useState(false)
    const [subject, setSubject] = React.useState("")
    const [message, setMessage] = React.useState("")
    const [emailAdd, setEmailAdd] = React.useState("")

    const handleClickOpen = (id, email) => {
        
            setOpen(true);
            setUid(id)
            setEmailAdd(email)
       
    };

    const handleClose = () => {
        setOpen(false);
    };

    const doMessage = async() =>{
        if(!message || !subject){
            toast.error("Message Subject and Message Content is required",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setSending(true)

            await addDoc(collection(db, "MembersInbox"), {
                MessageContent: message,
                MessageCreatedAt: serverTimestamp(),
                MessageReceiver: uid,
                MessageSenderID: "Devclub360 Admin",
                MessageSubject: subject
              }).then(async(msg)=>{
                    
                    await setDoc(doc(db, 'AdminSentMessages', msg.id),{
                        MessageContent: message,
                        MessageCreatedAt: serverTimestamp(),
                        MessageReceiver: uid,
                        MessageSenderID: "Devclub360 Admin",
                        MessageSubject: subject
                    }).then((_)=>{
                        fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                            method: "post",
                            headers: {
                            "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                            name: "Devcluber",
                            email: emailAdd,
                            subject: subject,
                            message: "<p>"+message+"</p>",
                            adminEmail: "operations@devclub360.com",
                            adminSubject: subject,
                            adminMessage: "<p>"+message+ " has been sent to "+emailAdd+"</p>"
                            })
                        }).then(res=>res.json())
                        .then(data=>{
                            console.log(data)
                        })

                        setMessage("")
                        setSubject("")
                        handleClose()
                        toast.success("Message has been successfully delivered",{
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }).catch((error)=>{
                    toast.error(error.code,{
                        position: toast.POSITION.TOP_RIGHT
                    })
                })
              }).catch((error)=>{
                toast.error(error.code,{
                    position: toast.POSITION.TOP_RIGHT
                })
              })
        }
    }
 
    return (
        <React.Fragment>
            <Container>
            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Send Message to this Member"}
                    </DialogTitle>
                    <DialogContent>
                    <TextField
                        fullWidth
                        name="subject"
                        placeholder= "Message Subject"
                        label="Message Subject"
                        type="text"
                        value={subject}
                        onChange={(e)=>setSubject(e.target.value)}
                        style={{marginBottom:"15px"}}
                    />

                    <TextField
                        fullWidth
                        name="content"
                        placeholder= "Message Content"
                        label="Message Content"
                        multiline
                        rows={5}
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        
                    />

                    </DialogContent>
                    <DialogActions>
                        {
                            isSending ? null : <Button onClick={handleClose}>Cancel</Button>
                        }
                    
                    {
                        isSending ? <CircularProgress />
                        :
                        <Button autoFocus onClick={doMessage}>
                        Send Message
                    </Button>
                    }
                    </DialogActions>
                </Dialog>
                <Row style={{marginTop:"15px"}}>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :

                            members.length === 0 ? 

                            <Grid align="center">
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography variant="h6">No Members Available yet</Typography>
                                </Paper>
                            </Grid>
                            :
                            <Grid>
                                <MaterialTable
                                    title= "Devclub360 Members"
                                    columns= {[
                                        {
                                            title: 'Profile Picture',
                                            field: 'ProfilePicsUrl',
                                            render: rowData => (
                                                <img
                                                  src={rowData.ProfilePicsUrl}
                                                  alt="profile"
                                                  style={{ width: 40, borderRadius: "50%" }}
                                                />
                                              )
                                        },
                                        {
                                            title: 'First Name',
                                            field: 'firstName'
                                        },
                                        {
                                            title: 'Last Name',
                                            field: 'lastName'
                                        },
                                        {
                                            title: 'Email Address',
                                            field: 'EmailAddress'
                                        },
                                        {
                                            title: 'Phone Number',
                                            field: 'PhoneNumber'
                                        },
                                        {
                                            title: 'Account Status',
                                            field: 'AccountStatus'
                                        },
                                        {
                                            title: 'View',
                                            field: 'view',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <Link to={"/dashboard/members/details/"+rowData.uid} style={{textDecoration: "none"}}>
                                                    <IconButton>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Link>
                                              </>
                                        },
                                        {
                                            title: 'Edit',
                                            field: 'edit',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>doEdit(rowData.uid)}>
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                
                                              </>
                                        },
                                        {
                                            title: 'Message',
                                            field: 'message',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>handleClickOpen(rowData.uid, rowData.EmailAddress)}>
                                                        <ForwardToInboxOutlinedIcon />
                                                    </IconButton>
                                                
                                              </>
                                        }
                                        
                                        
                                    ]}
                                    data= {members}
                                    
                                    options={{
                                        pageSize: 10,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </React.Fragment>
    )
}
