import React, {useEffect} from 'react'
import db from '../../../../config/firestore';
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, CircularProgress, Paper, IconButton} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import MaterialTable from 'material-table'
import { collection, getDocs } from "firebase/firestore/lite"
import {useHistory, Link} from 'react-router-dom'




export default function FeedssTable() {
    const [isLoading, setIsLoading] = React.useState(true)
    const [feeds, setFeeds] = React.useState([])
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const history = useHistory()

    const fetchFeeds= async()=>{
        
         const response = collection(db, 'Feeds');
         const data= await getDocs(response);
 
         data.forEach(item=>{
             
             setFeeds((prevState)=>{
               
                 return [...prevState, item.data()]
             })
            })
           setIsLoading(false)
       }

       

       useEffect(() => {
            if(currentAdmin){
                fetchFeeds();
            }else{
                history.push("/")
            } 

       }, [])

       const paperStyle = {
        padding: 20,
        height: '15vh',
        width: "100%",
        margin: "20px auto"
    }  
 
    return (
        <React.Fragment>
            <Container>
                <Row style={{marginTop:"15px"}}>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :

                            feeds.length == 0 ? 

                            <Grid align="center">
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography variant="h6">No Feed Available yet</Typography>
                                </Paper>
                            </Grid>
                            :
                            <Grid>
                                <MaterialTable
                                    title= "Devclub360 Feeds"
                                    columns= {[
                                        {
                                            title: 'Pricture',
                                            field: 'FeedCreatorPics',
                                            render: rowData => (
                                                <img
                                                  src={rowData.FeedCreatorPics}
                                                  alt="Profile Picture"
                                                  style={{ width: 40, borderRadius: "50%" }}
                                                />
                                              )
                                        },
                                        {
                                            title: 'Feed Creator',
                                            field: 'FeedCreatorName'
                                        },
                                        {
                                            title: 'Feed Title',
                                            field: 'FeedTitle'
                                        },
                                        {
                                            title: 'Feed Category',
                                            field: 'FeedType'
                                        },

                                        {
                                            title: 'View',
                                            field: 'view',
                                            render: (rowData)  => rowData && 
                                            <>
                                                {
                                                    rowData.FeedType=="Deal Connect" ?
                                                    <Link to={"/dashboard/deal-connect/details/"+rowData.DealConnectID} style={{textDecoration: "none"}}>
                                                    <IconButton>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Link>
                                                :
                                                    rowData.FeedType=="Deal Share" ?
                                                    <Link to={"/dashboard/deal-share/view/"+rowData.DealShareID} style={{textDecoration: "none"}}>
                                                    <IconButton>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Link>

                                                : 
                                                <Link to={"/dashboard/quick-posts/details/"+rowData.QuickPostId} style={{textDecoration: "none"}}>
                                                <IconButton>
                                                        <VisibilityIcon />
                                                </IconButton>
                                            </Link>
                                                }
                                              </>
                                        },
                                        
                                    ]}
                                    data= {feeds}
                                    
                                    options={{
                                        pageSize: 10,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
