import React from 'react'
import useStyles from '../styles/HeaderStyles'
import { Container, Row, Col } from 'react-bootstrap'
import {Grid, Typography, Divider} from '@mui/material'
import AddNewDeal from '../component/body/dealconnect/AddNewDeal'
import Header from '../component/header/Header'
import DealConnectTable from '../component/body/dealconnect/DealConnectTable'

export default function DealConnect() {
    const classes = useStyles()
    return (
        <React.Fragment>
            <Header />
            <Container className={classes.wrapper}>
                <Row>
                    <Col>
                        <Grid>
                            <Typography variant="h4">Deal Connect</Typography>
                            <Divider style={{width: "100%"}} />
                        </Grid>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AddNewDeal />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DealConnectTable />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
