import React from 'react'
import {Button, Grid, TextField, CircularProgress} from '@mui/material';
import AddIcon  from '@material-ui/icons/Add';
import useStyles from '../../../styles/HeaderStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import db from '../../../../config/firestore';
import {addDoc, doc, getDocs, serverTimestamp, collection, setDoc} from 'firebase/firestore/lite'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function CreateBroadcast(){
    const classes = useStyles()

    const [open, setOpen] = React.useState(false);
    const [isSending, setSending] = React.useState(false)
    const [subject, setSubject] = React.useState("")
    const [message, setMessage] = React.useState("")

    const handleClickOpen = () => {
        
            setOpen(true);
            
       
    };

    const handleClose = () => {
        setOpen(false);
    };

   

    const sendMessages = async()=>{
        if(!subject || !message){
            toast.error("Message subject and Message content are required")
        }else{
            if(window.confirm("Do you want to send Broadcast Message to all Members?")){
                try{
                    setSending(true)
                    const querySnapshot = await getDocs(collection(db, "Members"));
                    querySnapshot.forEach(async(data) => {
                        if(data.exists()){
                            const email = await data.get("EmailAddress")
                            

                            await addDoc(collection(db, "MembersInbox"), {
                                MessageContent: message,
                                MessageCreatedAt: serverTimestamp(),
                                MessageReceiver: data.id,
                                MessageSenderID: "Devclub360 Admin",
                                MessageSubject: subject
                              }).then(async(msg)=>{
                                    
                                    await setDoc(doc(db, 'AdminSentMessages', msg.id),{
                                        MessageContent: message,
                                        MessageCreatedAt: serverTimestamp(),
                                        MessageReceiver: data.id,
                                        MessageSenderID: "Devclub360 Admin",
                                        MessageSubject: subject
                                    }).then(async(_)=>{
                                       
                    
                                           await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                                            method: "post",
                                            headers: {
                                            "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify({
                                            name: "Devcluber",
                                            email: email,
                                            subject: subject,
                                            message: "<p>"+message+"</p>",
                                            adminEmail: "operations@devclub360.com",
                                            adminSubject: subject,
                                            adminMessage: "<p>"+message+ " has been sent to "+email+"</p>"
                                            })
                                        }).then(res=>res.json())
                                        .then(data=>{
                                            console.log(data)
                                        })
                                   
                                        
                                    }).catch((error)=>{
                                    toast.error(error.code,{
                                        position: toast.POSITION.TOP_RIGHT
                                    })
                                })
                              }).catch((error)=>{
                                toast.error(error.code,{
                                    position: toast.POSITION.TOP_RIGHT
                                })
                              })


                        }
                    
                    });
                    setSending(false)
                    handleClose()
                    toast.success("Message has been successfully delivered to all Members",{
                        position: toast.POSITION.TOP_RIGHT
                    })
                    

                }catch(e){
                    toast.error(e)
                }
            }else{
                handleClose()
                toast.info("Broadcast Messages terminated")
            }
        }
    }
    return (
        <React.Fragment>
            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Send Message to this Member"}
                    </DialogTitle>
                    <DialogContent>
                    <TextField
                        fullWidth
                        name="subject"
                        placeholder= "Message Subject"
                        label="Message Subject"
                        type="text"
                        value={subject}
                        onChange={(e)=>setSubject(e.target.value)}
                        style={{marginBottom:"15px"}}
                    />

                    <TextField
                        fullWidth
                        name="content"
                        placeholder= "Message Content"
                        label="Message Content"
                        multiline
                        rows={5}
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        
                    />

                    </DialogContent>
                    <DialogActions>
                        {
                            isSending ? null : <Button onClick={handleClose}>Cancel</Button>
                        }
                    
                    {
                        isSending ? <CircularProgress />
                        :
                        <Button autoFocus onClick={sendMessages}>
                        Send BroadCast
                    </Button>
                    }
                    </DialogActions>
                </Dialog>
                <Grid className= {classes.dealconnect}>
                            <div style= {{textAlign: 'right'}}>
                                    <Button 
                                        variant= "contained" 
                                        color="primary"
                                        size="large"
                                        className= {classes.button}
                                        startIcon ={<AddIcon />}
                                        onClick={handleClickOpen}
                                        >
                                            Create New Broadcast
                                    </Button>
                                    
                                </div>
                                
                            </Grid>
                            <ToastContainer />
        </React.Fragment>
    )
}