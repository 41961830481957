import React from 'react'
import {Button, Grid, TextField, CircularProgress} from '@mui/material';
import AddIcon  from '@material-ui/icons/Add';
import useStyles from '../../../styles/HeaderStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {doc, setDoc, serverTimestamp} from "firebase/firestore/lite";
import db from '../../../../config/firestore';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import firebaseApp from '../../../../config/config'


export default function AddStaffButton() {
    const classes = useStyles()
    const department = localStorage.getItem("adminDepartment")
    const auth = getAuth(firebaseApp)
    const [staffEmail, setStaffEmail] = React.useState("")
    const [staffPassword, setStaffPassword] = React.useState("")
    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [staffDepartment, setDepartment] = React.useState("Communications & Strategy")

    const [open, setOpen] = React.useState(false);

    const depts = [
        {
          value: 'Communications & Strategy',
          label: 'Communications & Strategy',
        },
        {
          value: 'Business & Projects',
          label: 'Business & Projects ',
        },
        {
          value: 'Support',
          label: 'Membership & Support',
        }
      ];

    const handleClickOpen = () => {
        
        if(department ==="Support" || department ==="Super Admin"){
            setOpen(true);
            
        }else{
            alert("Access Denied")
        }
        
        
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [isLoading, setIsLoading] = React.useState(false)

    const addNewStaff = async () =>{
        if(!staffEmail || !staffPassword || !firstName || !lastName){
            toast.error("All fields are required")
        }else{
            setIsLoading(true)

            createUserWithEmailAndPassword(auth, staffEmail, staffPassword)
                .then(async(userCredential) => {
                    await setDoc(doc(db, "Staffs", userCredential.user.uid), {
                        firstName: firstName,
                        lastName: lastName,
                        Department: staffDepartment,
                        AccountStatus: "Active",
                        AddedBy: localStorage.getItem("adminFirstName"),
                        AdminEmail: staffEmail,
                        createdAt: serverTimestamp(),
                        uid: userCredential.user.uid
                      }).then((_)=>{
                          toast.success("Staff added successfully")
                          setIsLoading(false)
                          setFirstName("")
                          setLastName("")
                          setStaffEmail("")
                          setStaffPassword("")
                          handleClose()
                          window.location.reload()
                      })
                })
                .catch((error) => {
                    toast.error(error.code)
                    setIsLoading(false)
                    // ..
            });
        }
    }
    return (
        <div>
            <Grid className= {classes.dealconnect}>

            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                    {"Create New Staff Account"}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            type="email"
                            label="Enter Staff Email"
                            value={staffEmail}
                            onChange={(e)=>setStaffEmail(e.target.value)}
                            style={{marginBottom: "10px"}}
                        />
                        <TextField
                            fullWidth
                            type="text"
                            value={firstName}
                            label="Staff First Name"
                            onChange={(e)=>setFirstName(e.target.value)}
                            style={{marginBottom: "10px"}}
                        />

                        <TextField
                            fullWidth
                            type="text"
                            value={lastName}
                            label="Staff Last Name"
                            onChange={(e)=>setLastName(e.target.value)}
                            style={{marginBottom: "10px"}}
                        />

                        <TextField
                            style={{marginBottom: "10px"}}
                            id="outlined-select-currency"
                            select
                            fullWidth
                            label="Select Staff Department"
                            value={staffDepartment}
                            onChange={(e)=>setDepartment(e.target.value)}
                            >
                            {depts.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            fullWidth
                            type="password"
                            value={staffPassword}
                            label="Staff Password"
                            onChange={(e)=>setStaffPassword(e.target.value)}
                            style={{marginBottom: "10px"}}
                        />
                    </DialogContent>
                    <DialogActions>
                    {
                        isLoading ? <CircularProgress />
                        :
                        <Grid>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button autoFocus onClick={addNewStaff}>
                                Add New Staff
                            </Button>
                        </Grid>
                    }
                    </DialogActions>
                </Dialog>
                            <div style= {{textAlign: 'right'}}>
                                    <Button 
                                        variant= "contained" 
                                        color="primary"
                                        size="large"
                                        className= {classes.button}
                                        startIcon ={<AddIcon />}
                                        onClick={handleClickOpen}
                                        >
                                            Add New Staff
                                    </Button>
                                    
                                </div>
                                
                            </Grid>

                        <ToastContainer />

        </div>
    )
}
