import React from 'react'
import {Button, Grid} from '@material-ui/core';
import AddIcon  from '@material-ui/icons/Add';
import useStyles from '../../../styles/HeaderStyles';


export default function AddMembersButton() {

    
    const classes = useStyles()

    const department = localStorage.getItem("adminDepartment")

    const handleClickOpen = () => {
        if(department ==="Support" || department ==="Super Admin"){
            alert("You will be redirected to devclub membership website to create new user")
            window.location.href= "https://www.devclub360.com/members/signup"
            
        }else{
            alert("Access Denied.. You are not allowed to Add New Member")
        }
    };
    return (
        <div>
            <Grid className= {classes.dealconnect}>
                            <div style= {{textAlign: 'right'}}>
                                    <Button 
                                        variant= "contained" 
                                        color="primary"
                                        size="large"
                                        className= {classes.button}
                                        startIcon ={<AddIcon />}
                                        onClick={handleClickOpen}
                                        
                                        >
                                            Add New Member
                                    </Button>
                                    
                                </div>
                                
                            </Grid>

        </div>
    )
}
