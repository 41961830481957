import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import Login from './before-login/Login';
import Dashboard from './dashboard/pages/Dashboard';
import Members from './dashboard/pages/Members';
import Staffs from './dashboard/pages/Staffs';
import Feeds from './dashboard/pages/Feeds';
import Announcements from './dashboard/pages/Announcements';
import DealConnect from './dashboard/pages/DealConnect';
import DealShare from './dashboard/pages/DealShare';
import Transactions from './dashboard/pages/Transactions'
import ViewDetails from './dashboard/component/body/members/ViewDetails';
import EditDetails from './dashboard/component/body/members/EditDetails';
import ViewDealConnect from './dashboard/component/body/dealconnect/ViewDealConnect'
import CreateNewDealShare from './dashboard/component/body/dealshare/CreateNewDealShare'
import QuickPosts from './dashboard/pages/QuickPosts';
import QuickPostSection from './dashboard/component/body/quickposts/QuickPostSection';
import Messages from './dashboard/pages/Messages';


function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/dashboard/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/members/all" component={Members} />
          <Route exact path="/dashboard/staffs/all" component={Staffs} />
          <Route exact path="/dashboard/feeds" component={Feeds} />
          <Route exact path="/dashboard/announcements" component={Announcements} />
          <Route exact path="/dashboard/deal-connect/all" component={DealConnect} />
          <Route exact path="/dashboard/deal-share/all" component={DealShare} />
          <Route exact path="/dashboard/transactions" component={Transactions} />
          <Route exact path="/dashboard/members/details/:id" component={ViewDetails} />
          <Route exact path="/dashboard/members/details/edit/:id" component={EditDetails} />
          <Route exact path="/dashboard/deal-connect/details/:id" component={ViewDealConnect} />
          <Route exact path="/dashboard/deal-share/create-new-deal" component={CreateNewDealShare} />
          <Route exact path="/dashboard/quick-posts/all" component={QuickPosts} />
          <Route exact path="/dashboard/quick-posts/details/:id" component={QuickPostSection} />
          <Route exact path="/dashboard/messages" component={Messages} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
