import React, {useEffect} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, Divider, Avatar, CircularProgress, Button} from '@mui/material'
import Header from '../../header/Header'
import useStyles from '../../../styles/HeaderStyles'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {doc, getDoc, updateDoc, serverTimestamp} from "firebase/firestore/lite";
import db from '../../../../config/firestore'



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

export default function ViewDetails() {

    const {id} = useParams()

    
    
    const history = useHistory()
    const classes =  useStyles()
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")

    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true)

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("") 
    const [DealToCloseInNextQuarter, setDealToCloseInNextQuarter] = React.useState("")
    const [HomeAddress, setHomeAddress] = React.useState("")
    const [memberEmail, setMemberEmail] = React.useState("")
    const [accountStatus, setAccountStatus] = React.useState("")
    const [creation, setCreation] = React.useState("")
    const [LinkedInProfile, setLinkedInProfile] = React.useState("");
    const [MembersAchievement, setMembersAchievement] = React.useState("")
    const [MemberCategory, setMemberCategory] = React.useState("")
    const [PhoneNumber, setPhoneNumber] = React.useState("")
    const [YearlyWorth, setYearlyWorth] = React.useState("")
    const [ProfilePicsUrl, setProfilePicsUrl] = React.useState("")
    const [lastTransactionWorth, setlastTransactionWorth] = React.useState("")

    //verification details
    const [CAC, setCAC] = React.useState("")
    const [DriversLicense, setDriversLicense] = React.useState("")
    const [NIN, setNIN] = React.useState("")
    const [TIN, setTIN] = React.useState("")
    const [VIN, setVIN] = React.useState("")

    //Company details

    const [CompanyName, setCompanyName] = React.useState("")
    const [CompanyWebsite, setCompanyWebsite] = React.useState("")
    const [MarketArea, setMarketArea] = React.useState([])
    const [PracticeArea, setPracticeArea] = React.useState([])
    const [position, setPosition] = React.useState("")
    const [representative, setRepresentative] = React.useState("")
    const [email, setEmail] = React.useState("")

    //membership detail
    const [status, setStatus] = React.useState("")
    const [memId, setMemId] = React.useState("")

    const docRef = doc(db, "Members", id);
    const comRef = doc(db, "MembersCompanyDetails", id);
    const memRef = doc(db, "MembershipDetails", id);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        
        if(department ==="Support" || department ==="Super Admin"){
            setOpen(true);
            
        }else{
            alert("Access Denied.. You are not allowed to Activate Account")
        }
        
        
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [activate, setActivate] = React.useState('Active On Trial');

  const handleActivateChange = (event) => {
    setActivate(event.target.value);
  };

  const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        if(department ==="Support" || department ==="Super Admin"){
            setOpen1(true);
            
        }else{
            alert("Access Denied.. You are not allowed to Activate Account")
        }
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const [deactivate, setDeActivate] = React.useState('Suspended');

  const handleDeActivateChange = (event) => {
    setDeActivate(event.target.value);
  };

  

    const fetchProfile = async()=>{
        

        await getDoc(docRef).then(async(docs)=>{
            if(docs.exists()){
                setFirstName(docs.get("firstName"))
                setLastName(await docs.get("lastName"))
                setDealToCloseInNextQuarter(await docs.get("DealToCloseInNextQuarter"))
                setHomeAddress(await docs.get("HomeAddress"))
                setLinkedInProfile(await docs.get("LinkedInProfile"))
                setMemberCategory(await docs.get("MemberCategory"))
                setMembersAchievement(await docs.get("MembersAchievement"))
                setPhoneNumber(await docs.get("PhoneNumber"))
                setProfilePicsUrl(await docs.get("ProfilePicsUrl"))
                setYearlyWorth(await docs.get("YearlyWorth"))
                setMemberEmail(await docs.get("EmailAddress"))
                setAccountStatus(await docs.get("AccountStatus"))
                setCreation(new Date(await docs.get("CreatedAt") * 1000).getDate()+
                "/"+(new Date(await docs.get("CreatedAt") * 1000).getMonth()+1)+
                " "+new Date(await docs.get("CreatedAt") * 1000).getHours()+
                ":"+new Date(await docs.get("CreatedAt") * 1000).getMinutes()+
                ":"+new Date(await docs.get("CreatedAt") * 1000).getSeconds())
                setlastTransactionWorth(await docs.get("lastTransactionWorth"))
                setCAC(await docs.get("CAC"))
                setDriversLicense(await docs.get("DriversLicense"))
                setTIN(await docs.get("TIN"))
                setVIN(await docs.get("VIN"))
                setNIN(await docs.get("NIN"))

                await getDoc(comRef).then(async(company)=>{
                    if(company.exists()){
                        setCompanyName(await company.get("CompanyName"))
                        setCompanyWebsite(await company.get("CompanyWebsite"))
                        setMarketArea(await company.get("MarketArea"))
                        setPracticeArea(await company.get("PracticeArea"))
                        setPosition(await company.get("PositionHeld"))
                        setRepresentative(await company.get("RepresentativeName"))
                        setEmail(await company.get("CorporateEmail"))
                        
                    }
                })

                await getDoc(memRef).then(async(mem)=>{
                    if(mem.exists()){
                        setStatus(await mem.get("MembershipStatus"))
                        setMemId(await mem.get("MemberShipId"))
                    }
                })

                setIsLoading(false)

            }else{
                alert("Failed to load Profile. Contact support if this problem persists")
                history.push("/")
            }
        })
    }

    useEffect(() => {
        if(currentAdmin){
            fetchProfile()
        }else{
            history.push("/")
        }
    }, [])

    const activateAccount = async()=>{
       

       if(activate ==="Active On Trial" && accountStatus === "Active On Trial"){
           toast.info("Member's Account already activated as Active On Trial...Change Activation Option",{
               position: toast.POSITION.TOP_RIGHT
           })
           handleClose()
           
       }else if(activate ==="Active" && accountStatus === "Active"){
        toast.info("Member's Account already activated as Active... Change Activation Option",{
            position: toast.POSITION.TOP_RIGHT
        })
        handleClose()
        
      }else if(activate ==="Active On Payment Confirmation" && accountStatus === "Active On Payment Confirmation"){
        toast.info("Member's Account already activated as Active On Payment Confirmation... Change Activation Option",{
            position: toast.POSITION.TOP_RIGHT
        })
        handleClose()
        
      }else if(activate ==="Verified" && accountStatus === "Verified"){
        toast.info("Member's Account already activated as Verified... Change Activation Option",{
            position: toast.POSITION.TOP_RIGHT
        })
        handleClose()
      }else if(activate ==="Active"){
        await updateDoc(docRef, {
            AccountStatus: activate
          }).then(async(_)=>{
            toast.success("Member's Account Successfully Changed to Active",{
                position: toast.POSITION.TOP_RIGHT
            })

           await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                    method: "post",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                      name: "Devcluber",
                      email: memberEmail,
                      subject: "Devclub Membership Account Activated Successfully",
                      message: "<p>Welcome to the DEVCLUB360.</p><p> First and foremost, we want to thank you for your investment. Without your loyalty and support, we would not be able to continuously provide our network with valuable benefits and actively develop the world of real estate.</p><p> Put simply, you make what we do possible.</p><p>We want to see you benefit from your membership. If not, let us fix that right away!</p><p> Call or email the DEVCLUB360 to see how we may better meet your needs.</p><p>Beyond the sweet deals available on the DEVCLUB360, you will have your own personal board of directors, meet new high net worth friends through our several events like the Quarterly Board Room Meetings, Boat Cruises, and yearly Portfolio Defense Meetings.</p><p>If, like most of our members, you are satisfied with your membership, consider introducing your highly placed friends to the DEVCLUB360.</p><p>Kind regards<br />ONAIFO UWA<br />Founder/CEO</p>",
                      adminEmail: "payments@devclub360.com",
                      adminSubject: "Devclub Membership Account Activated Successfully",
                      adminMessage: "<p>This is to notify you that a member with email "+memberEmail+" has been activated successfully.</p>"
                    })
                  }).then(res=>res.json())
                  .then(data=>{
                    console.log(data)
                  })
            
            handleClose()
            window.location.reload()
          }).catch((error)=>{
            toast.error(error.code,{
                position: toast.POSITION.TOP_RIGHT
            })
          })
      }else if(activate ==="Active On Trial"){
        await updateDoc(docRef, {
            AccountStatus: activate,
            CreatedAt: serverTimestamp()
          }).then(async(_)=>{
            toast.success("Member's Account Successfully Changed to Active On Trial",{
                position: toast.POSITION.TOP_RIGHT
            })
           await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                  method: "post",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    name: firstName,
                    email: memberEmail,
                    subject: "Successful Devclub360 Membership Activation on Trial",
                    message: "<p>Welcome to the DEVELOPERS CLUB 360; DEVCLUB360. You can now enjoy full access, post your real estate deals, invest in deals, and make connections within the next 30 days after which you will be required to make your membership fee of $2,500.</p><p>Our entire focus is to facilitate real estate business, friendship, wealth preservation, and investment in Africa. Use the Dashboard to Post or to get more business deals.</p><p>The opportunities here are endless.</p><p>Thank You!<br /></p>",
                    adminEmail: "operations@devclub360.com",
                    adminSubject: "New Successful Member Activation on Trial",
                    adminMessage: "<p>This is to confirm to You that "+memberEmail+" account has been activated on Trial.</p>"
                  })
                }).then(res=>res.json())
                .then(data=>{
                  console.log(data)
                })
            handleClose()
            window.location.reload()
          }).catch((error)=>{
            toast.error(error.code,{
                position: toast.POSITION.TOP_RIGHT
            })
          })
      }else if(activate ==="Active On Payment Confirmation"){
        await updateDoc(docRef, {
            AccountStatus: activate,
          }).then((_)=>{
            toast.success("Member's Account Successfully Changed to "+activate,{
                position: toast.POSITION.TOP_RIGHT
            })
            handleClose()
            window.location.reload()
          }).catch((error)=>{
            toast.error(error.code,{
                position: toast.POSITION.TOP_RIGHT
            })
          })
      }else if(activate ==="Verified"){
        await updateDoc(docRef, {
            AccountStatus: activate,
          }).then(async(_)=>{
           await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                method: "post",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  name: firstName,
                  email: memberEmail,
                  subject: "Successful Devclub360 Membership Verification",
                  message: "<p>This is to confirm to you that your Devclub360 Account has been marked as verified. Enjoy full access, post your real estate deals, invest in deals, and make connections.</p><p>Our entire focus is to facilitate real estate business, friendship, wealth preservation, and investment in Africa. Use the Dashboard to Post or to get more business deals.</p><p>The opportunities here are endless.</p><p>Thank You!<br /></p>",
                  adminEmail: "operations@devclub360.com",
                  adminSubject: "Successful Devclub360 Membership Verification",
                  adminMessage: "<p>This is to confirm to You that "+memberEmail+" account has been Verified completely.</p>"
                })
              }).then(res=>res.json())
              .then(data=>{
                console.log(data)
              })
            toast.success("Member's Account Successfully Changed to "+activate,{
                position: toast.POSITION.TOP_RIGHT
            })

            handleClose()
            window.location.reload()
          }).catch((error)=>{
            toast.error(error.code,{
                position: toast.POSITION.TOP_RIGHT
            })
          })
      }
      
    }

    const deActivateAccount = async()=>{
       

        if(deactivate ==="Suspended" && accountStatus === "Suspended"){
            toast.info("Member's Account already Deactivated as Suspended...Change DeActivation Option",{
                position: toast.POSITION.TOP_RIGHT
            })
            handleClose1()
        }else if(deactivate ==="Terminated" && accountStatus === "Terminated"){
         toast.info("Member's Account already Deactivated as Terminated... Change DeActivation Option",{
             position: toast.POSITION.TOP_RIGHT
         })
         handleClose1()
       }else if(deactivate ==="Suspended"){
         await updateDoc(docRef, {
             AccountStatus: deactivate
           }).then(async(_)=>{
           await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                method: "post",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  name: firstName,
                  email: memberEmail,
                  subject: "Successful Devclub360 Membership Account Deactivation Notice",
                  message: "<p>This is to confirm to you that your Devclub360 Account has been deactivated.</p> <p>This is because you breached the terms and conditions that have been set as guidelines to use the Declub360.</p><p>To activate account, please contact our support desk at<br /> helpdesk@devclub360.com.</p><p>Thank You!<br /></p>",
                  adminEmail: "operations@devclub360.com",
                  adminSubject: "Successful Devclub360 Membership Deactivation",
                  adminMessage: "<p>This is to confirm to You that "+memberEmail+" account has been Deactivated.</p>"
                })
              }).then(res=>res.json())
              .then(data=>{
                console.log(data)
              })
             toast.success("Member's Account Successfully Changed to "+deactivate,{
                 position: toast.POSITION.TOP_RIGHT
             })
             handleClose1()
             window.location.reload()
           }).catch((error)=>{
             toast.error(error.code,{
                 position: toast.POSITION.TOP_RIGHT
             })
           })
       }else if(deactivate ==="Terminated"){
         await updateDoc(docRef, {
             AccountStatus: deactivate,
           }).then(async(_)=>{
           await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                method: "post",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  name: firstName,
                  email: memberEmail,
                  subject: "Successful Devclub360 Membership Account Deactivation Notice",
                  message: "<p>This is to confirm to you that your Devclub360 Account has been deactivated.</p> <p>This is because you breached the terms and conditions that have been set as guidelines to use the Declub360.</p><p>To activate account, please contact our support desk at<br /> helpdesk@devclub360.com.</p><p>Thank You!<br /></p>",
                  adminEmail: "operations@devclub360.com",
                  adminSubject: "Successful Devclub360 Membership Deactivation",
                  adminMessage: "<p>This is to confirm to You that "+memberEmail+" account has been Deactivated.</p>"
                })
              }).then(res=>res.json())
              .then(data=>{
                console.log(data)
              })
             toast.success("Member's Account Successfully Changed to "+deactivate,{
                 position: toast.POSITION.TOP_RIGHT
             })
             handleClose1()
             window.location.reload()
           }).catch((error)=>{
             toast.error(error.code,{
                 position: toast.POSITION.TOP_RIGHT
             })
           })
       }
       
     }

    
    return (
        <React.Fragment>
            <Header />
            <Container className={classes.wrapper}>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Activate Account as?"}
                    </DialogTitle>
                    <DialogContent>
                    <FormControl component="fieldset">
                        
                        <RadioGroup
                            aria-label="Activate"
                            name="controlled-radio-buttons-group"
                            value={activate}
                            onChange={handleActivateChange}
                        >
                            <FormControlLabel value="Active On Trial" control={<Radio />} label="Active On Trial" />
                            <FormControlLabel value="Active" control={<Radio />} label="Active" />
                            <FormControlLabel value="Active On Payment Confirmation" control={<Radio />} label="Active On Payment Confirmation" />
                            <FormControlLabel value="Verified" control={<Radio />} label="Verified" />
                        </RadioGroup>
                    </FormControl>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={activateAccount} autoFocus>
                        Continue
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"DeActivate Account as?"}
                    </DialogTitle>
                    <DialogContent>
                    <FormControl component="fieldset">
                        
                        <RadioGroup
                            aria-label="Deactivate"
                            name="controlled-radio-buttons-group"
                            value={deactivate}
                            onChange={handleDeActivateChange}
                        >
                            <FormControlLabel value="Suspended" control={<Radio />} label="Suspended" />
                            <FormControlLabel value="Terminated" control={<Radio />} label="Terminated" />
                            
                        </RadioGroup>
                    </FormControl>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose1}>Cancel</Button>
                    <Button onClick={deActivateAccount} autoFocus>
                        Continue
                    </Button>
                    </DialogActions>
                </Dialog>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Personal" {...a11yProps(0)} />
                        <Tab label="Verification" {...a11yProps(1)} />
                        <Tab label="Company" {...a11yProps(2)} />
                        <Tab label="Membership" {...a11yProps(3)} />
                        <Tab label="Transaction History" {...a11yProps(4)} />
                        <Tab label="Payment History" {...a11yProps(5)} />
                        <Tab label="Quick Post" {...a11yProps(6)} />
                        <Tab label="Created Deals" {...a11yProps(7)} />
                        <Tab label="Interested Deals" {...a11yProps(8)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            : 

                            <Container>
                                <Row align="center" style={{marginBottom: "24px"}}>
                                    <Col>
                                        <Avatar src={ProfilePicsUrl} alt ="profile" sx={{ width: 80, height: 80 }} />
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><Typography variant="inherit">First Name</Typography></Col>
                                    <Col><Typography variant="inherit">{firstName}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Last Name</Typography></Col>
                                    <Col><Typography variant="inherit">{lastName}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Email Address</Typography></Col>
                                    <Col><Typography variant="inherit">{memberEmail}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Home Address</Typography></Col>
                                    <Col><Typography variant="inherit">{HomeAddress}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Phone Number</Typography></Col>
                                    <Col><Typography variant="inherit">{PhoneNumber}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">LinkedIn Profile</Typography></Col>
                                    <Col><Typography variant="inherit">{LinkedInProfile}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Account Status</Typography></Col>
                                    <Col><Typography variant="inherit">{accountStatus}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Date Created</Typography></Col>
                                    <Col><Typography variant="inherit">{creation.toString()}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "15px"}}>
                                    <Col><Typography variant="h6" align="left">Personal Achievements</Typography></Col>
                                </Row>
                                <Divider />
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Deal To close in next Qtrs</Typography></Col>
                                    <Col><Typography variant="inherit">{"USD"+DealToCloseInNextQuarter}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Member's Category</Typography></Col>
                                    <Col><Typography variant="inherit">{MemberCategory}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Achievements</Typography></Col>
                                    <Col><Typography variant="inherit">{MembersAchievement}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Last Transaction Worth</Typography></Col>
                                    <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(lastTransactionWorth))}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Yearly Transaction</Typography></Col>
                                    <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(YearlyWorth))}</Typography></Col>
                                </Row>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            : 
                            <Container>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">CAC</Typography></Col>
                                    <Col><Typography variant="inherit">{CAC === "" ? "Not Available": CAC}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Driver's License</Typography></Col>
                                    <Col><Typography variant="inherit">{DriversLicense === "" ? "Not Available": DriversLicense}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">TIN</Typography></Col>
                                    <Col><Typography variant="inherit">{TIN === "" ? "Not Available": TIN}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">NIN</Typography></Col>
                                    <Col><Typography variant="inherit">{NIN === "" ? "Not Available": NIN}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">VIN</Typography></Col>
                                    <Col><Typography variant="inherit">{VIN === "" ? "Not Available": VIN}</Typography></Col>
                                </Row>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {
                            isLoading? 

                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Company's Name</Typography></Col>
                                    <Col><Typography variant="inherit">{CompanyName}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Company's Website</Typography></Col>
                                    <Col><Typography variant="inherit">{CompanyWebsite===""?"Not Available": CompanyWebsite}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Market Area</Typography></Col>
                                    <Col>{
                                        MarketArea.map(item=>{
                                            return <Typography variant="inherit">{item}</Typography>
                                        })
                                    }</Col>
                                </Row>

                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Practice Area</Typography></Col>
                                    <Col>{
                                        PracticeArea.map(item=>{
                                            return <Typography variant="inherit">{item}</Typography>
                                        })
                                    }</Col>
                                </Row>

                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Position Held</Typography></Col>
                                    <Col><Typography variant="inherit">{position}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Representative's Name</Typography></Col>
                                    <Col><Typography variant="inherit">{representative}</Typography></Col>
                                </Row>

                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Corporate Email</Typography></Col>
                                    <Col><Typography variant="inherit">{email}</Typography></Col>
                                </Row>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {
                            isLoading? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">MemberShip Id</Typography></Col>
                                    <Col><Typography variant="inherit">{
                                        memId.length===1 ? "INVESTOR00"+memId: memId.length===2 ? "INVESTOR0"+memId: "INVESTOR"+memId
                                    }</Typography></Col>
                                </Row>

                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">MemberShip Status</Typography></Col>
                                    <Col><Typography variant="inherit">{status}</Typography></Col>
                                </Row>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        {
                            isLoading? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Typography>Transaction</Typography>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        {
                            isLoading? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Typography>Payment</Typography>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        {
                            isLoading? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Typography>Quick Posts</Typography>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                        {
                            isLoading? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Typography>Created Deals</Typography>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                        {
                            isLoading? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Typography>Interested Deals</Typography>
                            </Container>
                        }
                    </TabPanel>
                </Box>

                {
                    isLoading ? null :

                    <Grid align="center">
                        <Row>
                            <Col>
                                <Button fullWidth variant="contained" color="success" onClick={handleClickOpen}>Activate Account</Button>
                            </Col>

                            <Col>
                                <Button fullWidth variant="contained" color="primary" onClick={handleClickOpen1}>Deactivate Account</Button>
                            </Col>
                        </Row>
                </Grid>
                }
                <ToastContainer />
            </Container>
        </React.Fragment>
    )
}
