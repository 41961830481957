import React, {useEffect} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, Divider, Avatar, CircularProgress, Button, TextField} from '@mui/material'
import Header from '../../header/Header'
import useStyles from '../../../styles/HeaderStyles'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {doc, getDoc, updateDoc} from "firebase/firestore/lite";
import db from '../../../../config/firestore'
import firebaseApp from '../../../../config/config'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

export default function EditDetails() {
    const {id} = useParams()

    const auth = getAuth(firebaseApp)
    
    const history = useHistory()
    const classes =  useStyles()
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))

    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true)

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("") 
    const [DealToCloseInNextQuarter, setDealToCloseInNextQuarter] = React.useState("")
    const [HomeAddress, setHomeAddress] = React.useState("")
    const [memberEmail, setMemberEmail] = React.useState("")
    const [accountStatus, setAccountStatus] = React.useState("")
    const [creation, setCreation] = React.useState("")
    const [LinkedInProfile, setLinkedInProfile] = React.useState("");
    const [MembersAchievement, setMembersAchievement] = React.useState("")
    const [MemberCategory, setMemberCategory] = React.useState("")
    const [PhoneNumber, setPhoneNumber] = React.useState("")
    const [YearlyWorth, setYearlyWorth] = React.useState("")
    const [ProfilePicsUrl, setProfilePicsUrl] = React.useState("")
    const [lastTransactionWorth, setlastTransactionWorth] = React.useState("")

    //verification details
    const [CAC, setCAC] = React.useState("")
    const [DriversLicense, setDriversLicense] = React.useState("")
    const [NIN, setNIN] = React.useState("")
    const [TIN, setTIN] = React.useState("")
    const [VIN, setVIN] = React.useState("")

    //Company details

    const [CompanyName, setCompanyName] = React.useState("")
    const [CompanyWebsite, setCompanyWebsite] = React.useState("")
    const [MarketArea, setMarketArea] = React.useState([])
    const [PracticeArea, setPracticeArea] = React.useState([])
    const [position, setPosition] = React.useState("")
    const [representative, setRepresentative] = React.useState("")
    const [email, setEmail] = React.useState("")

    //membership detail
    const [status, setStatus] = React.useState("")
    const [memId, setMemId] = React.useState("")

    const docRef = doc(db, "Members", id);
    const comRef = doc(db, "MembersCompanyDetails", id);
    const memRef = doc(db, "MembershipDetails", id);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);
    const [image, setImage]= React.useState()

    const handleClickOpen = () => {
        
            setOpen(true);
       
    };

    const handleClose = () => {
        setOpen(false);
    };

                
    const fetchProfile = async()=>{
        

        await getDoc(docRef).then(async(docs)=>{
            if(docs.exists()){
                setFirstName(docs.get("firstName"))
                setLastName(await docs.get("lastName"))
                setDealToCloseInNextQuarter(await docs.get("DealToCloseInNextQuarter"))
                setHomeAddress(await docs.get("HomeAddress"))
                setLinkedInProfile(await docs.get("LinkedInProfile"))
                setMemberCategory(await docs.get("MemberCategory"))
                setMembersAchievement(await docs.get("MembersAchievement"))
                setPhoneNumber(await docs.get("PhoneNumber"))
                setProfilePicsUrl(await docs.get("ProfilePicsUrl"))
                setYearlyWorth(await docs.get("YearlyWorth"))
                setMemberEmail(await docs.get("EmailAddress"))
                setAccountStatus(await docs.get("AccountStatus"))
                setCreation(new Date(await docs.get("CreatedAt") * 1000).getDate()+
                "/"+(new Date(await docs.get("CreatedAt") * 1000).getMonth()+1)+
                " "+new Date(await docs.get("CreatedAt") * 1000).getHours()+
                ":"+new Date(await docs.get("CreatedAt") * 1000).getMinutes()+
                ":"+new Date(await docs.get("CreatedAt") * 1000).getSeconds())
                setlastTransactionWorth(await docs.get("lastTransactionWorth"))
                setCAC(await docs.get("CAC"))
                setDriversLicense(await docs.get("DriversLicense"))
                setTIN(await docs.get("TIN"))
                setVIN(await docs.get("VIN"))
                setNIN(await docs.get("NIN"))

                await getDoc(comRef).then(async(company)=>{
                    if(company.exists()){
                        setCompanyName(await company.get("CompanyName"))
                        setCompanyWebsite(await company.get("CompanyWebsite"))
                        setMarketArea(await company.get("MarketArea"))
                        setPracticeArea(await company.get("PracticeArea"))
                        setPosition(await company.get("PositionHeld"))
                        setRepresentative(await company.get("RepresentativeName"))
                        setEmail(await company.get("CorporateEmail"))
                        
                    }
                })

                await getDoc(memRef).then(async(mem)=>{
                    if(mem.exists()){
                        setStatus(await mem.get("MembershipStatus"))
                        setMemId(await mem.get("MemberShipId"))
                    }
                })

                setIsLoading(false)

            }else{
                alert("Failed to load Profile. Contact support if this problem persists")
                history.push("/")
            }
        })
    }

    useEffect(() => {
        if(currentAdmin){
            fetchProfile()
        }else{
            history.push("/")
        }
    }, [])
    const [isUploading, setIsUploading] = React.useState(false)
    const resetPassword = async ()=>{
        sendPasswordResetEmail(auth, memberEmail)
        .then(() => {
          toast.success("A Password reset email has been sent to this member",{
              position: toast.POSITION.TOP_RIGHT
          })
        })
        .catch((error) => {
            toast.error(error.code,{
                position: toast.POSITION.TOP_RIGHT
            })
        });
    }

    

    const changeImage = async()=>{
        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if(!image){
            toast.error("Please choose a profile Picture",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else if(!allowedExtensions.exec(image.name)){
            toast.error("Please choose a valid profile picture of .jpg, .jpeg, .png", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else{
            setIsUploading(true)
            const data = new FormData()
            data.append("file", image)
            data.append("upload_preset", "devclub360")
            data.append("cloud_name", "dnnbqnnnl")

            fetch("https://api.cloudinary.com/v1_1/dnnbqnnnl/image/upload",{
            method: "post",
            body: data
            }).then(res=>res.json())
            .then(data=>{
                

                updateDoc(docRef, {
                    ProfilePicsUrl: data.secure_url
                }).then((_)=>{
                    
                    setIsUploading(false)
                    handleClose()
                    toast.success("Member's Profile Picture has been successfully updated",{
                        position: toast.POSITION.TOP_RIGHT
                    })
                    window.location.reload()
                }).catch((error)=>{
                    toast.error(error,{
                        position: toast.POSITION.TOP_RIGHT
                    })
                })
            })
            .catch(err=>{
                
                    toast.error("An error was encountered while saving your data, please refresh the page and try again", {
                    position: toast.POSITION.TOP_RIGHT
                    });
            })

        }
        
    }
    const [isUpdating, setIsUpdating] = React.useState(false)

    const updateAccount = async () =>{
        if(!firstName || !lastName || !HomeAddress || !PhoneNumber || !LinkedInProfile){
            toast.error("Some fields are emptyand are required",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setIsUpdating(true)
            updateDoc(docRef,{
                firstName: firstName,
                lastName: lastName,
                HomeAddress: HomeAddress,
                PhoneNumber: PhoneNumber,
                LinkedInProfile: LinkedInProfile
            }).then((_)=>{
                toast.success("Account Details Updated successfully...Reload your Page to see effect",{
                    position: toast.POSITION.TOP_RIGHT
                })

                setIsUpdating(false)
            })
        }
    }

    const updateVerification = async () =>{
        
            setIsUpdating(true)
            updateDoc(docRef,{
                CAC: CAC,
                TIN: TIN,
                DriversLicense: DriversLicense,
                VIN: VIN,
                NIN: NIN
            }).then((_)=>{
                toast.success("Verification Details Updated successfully...Reload your Page to see effect",{
                    position: toast.POSITION.TOP_RIGHT
                })

                setIsUpdating(false)
            })
        
    }

    const updateCompany = async () =>{
        if(!CompanyName || !representative || !email || !position){
            toast.error("Some fields are empty and are required",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setIsUpdating(true)
            updateDoc(comRef,{
                CompanyName: CompanyName,
                CompanyWebsite: CompanyWebsite,
                CorporateEmail: email,
                RepresentativeName: representative,
                PositionHeld: position
            }).then((_)=>{
                toast.success("Company Details Updated successfully...Reload your Page to see effect",{
                    position: toast.POSITION.TOP_RIGHT
                })

                setIsUpdating(false)
            })
        }
    }
    
    return (
        <React.Fragment>
            <Header />
            <Container className={classes.wrapper}>

            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Upload New Image for this member"}
                    </DialogTitle>
                    <DialogContent>
                    <TextField
                        fullWidth
                        name="image"
                        type="file"
                        onChange={(e)=>setImage(e.target.files[0])}
                        
                    />
                    </DialogContent>
                    <DialogActions>
                        {
                            isUploading ? null : <Button onClick={handleClose}>Cancel</Button>
                        }
                    
                    {
                        isUploading ? <CircularProgress />
                        :
                        <Button autoFocus onClick={changeImage}>
                        Continue
                    </Button>
                    }
                    </DialogActions>
                </Dialog>
            <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Personal" {...a11yProps(0)} />
                            <Tab label="Verification" {...a11yProps(1)} />
                            <Tab label="Company" {...a11yProps(2)} />
                            <Tab label="Membership" {...a11yProps(3)} />
                        
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            : 

                            <Container>
                                <Row align="center" style={{marginBottom: "24px"}}>
                                    <Col>
                                       <Row>
                                            <Avatar src={ProfilePicsUrl} alt ="profile" sx={{ width: "60%", height: "60%" }} />
                                            <Button variant="text" color="primary" onClick={handleClickOpen}>Change</Button>
                                       </Row>
                                        
                                    </Col>
                                    <Col>
                                        <Button variant="contained" color="success" fullWidth onClick={resetPassword}>Reset Password</Button>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col><Typography variant="inherit">First Name</Typography></Col>
                                    <Col>
                                        <TextField value={firstName} fullWidth onChange={(e)=>setFirstName(e.target.value)} />
                                    </Col>
                                    
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Last Name</Typography></Col>
                                    <Col>
                                        <TextField value={lastName} fullWidth onChange={(e)=>setLastName(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Email Address</Typography></Col>
                                    <Col><Typography variant="inherit">{memberEmail}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Home Address</Typography></Col>
                                    <Col>
                                        <TextField value={HomeAddress} fullWidth onChange={(e)=>setHomeAddress(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Phone Number</Typography></Col>
                                    <Col>
                                        <TextField value={PhoneNumber} fullWidth onChange={(e)=>setPhoneNumber(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">LinkedIn Profile</Typography></Col>
                                    <Col>
                                        <TextField value={LinkedInProfile} fullWidth onChange={(e)=>setLinkedInProfile(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Account Status</Typography></Col>
                                    <Col><Typography variant="inherit">{accountStatus}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Date Created</Typography></Col>
                                    <Col><Typography variant="inherit">{creation.toString()}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "15px"}}>
                                    <Col><Typography variant="h6" align="left">Personal Achievements</Typography></Col>
                                </Row>
                                <Divider />
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Deal To close in next Qtrs</Typography></Col>
                                    <Col><Typography variant="inherit">{"USD"+DealToCloseInNextQuarter}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Member's Category</Typography></Col>
                                    <Col><Typography variant="inherit">{MemberCategory}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Achievements</Typography></Col>
                                    <Col><Typography variant="inherit">{MembersAchievement}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Last Transaction Worth</Typography></Col>
                                    <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(lastTransactionWorth))}</Typography></Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Yearly Transaction</Typography></Col>
                                    <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(YearlyWorth))}</Typography></Col>
                                </Row>

                                <Row>
                                    <Col>
                                    {
                                        isUpdating ? <Grid>
                                            <CircularProgress />
                                        </Grid>
                                        :
                                        <Button fullWidth color="success" variant="contained" onClick={updateAccount}>Update Account</Button>
                                    }
                                        
                                    </Col>
                                </Row>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            : 
                            <Container>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">CAC</Typography></Col>
                                    <Col>
                                        <TextField value={CAC} fullWidth onChange={(e)=>setCAC(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Driver's License</Typography></Col>
                                    <Col>
                                        <TextField value={DriversLicense} fullWidth onChange={(e)=>setDriversLicense(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">TIN</Typography></Col>
                                    <Col>
                                        <TextField value={TIN} fullWidth onChange={(e)=>setTIN(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">NIN</Typography></Col>
                                    <Col>
                                        <TextField value={NIN} fullWidth onChange={(e)=>setNIN(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">VIN</Typography></Col>
                                    <Col>
                                        <TextField value={VIN} fullWidth onChange={(e)=>setVIN(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    {
                                        isUpdating ? <Grid>
                                            <CircularProgress />
                                        </Grid>
                                        :
                                        <Button fullWidth color="success" variant="contained" onClick={updateVerification}>Update Verification Details</Button>
                                    }
                                    </Col>
                                </Row>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {
                            isLoading? 

                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Company's Name</Typography></Col>
                                    <Col>
                                        <TextField value={CompanyName} fullWidth onChange={(e)=>setCompanyName(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Company's Website</Typography></Col>
                                    <Col>
                                        <TextField value={CompanyWebsite} fullWidth onChange={(e)=>setCompanyWebsite(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Market Area</Typography></Col>
                                    <Col>{
                                        MarketArea.map(item=>{
                                            return <Typography variant="inherit">{item}</Typography>
                                        })
                                    }</Col>
                                </Row>

                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Practice Area</Typography></Col>
                                    <Col>{
                                        PracticeArea.map(item=>{
                                            return <Typography variant="inherit">{item}</Typography>
                                        })
                                    }</Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Position Held</Typography></Col>
                                    <Col>
                                        <TextField value={position} fullWidth onChange={(e)=>setPosition(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Representative's Name</Typography></Col>
                                    <Col>
                                        <TextField value={representative} fullWidth onChange={(e)=>setRepresentative(e.target.value)} />
                                    </Col>
                                </Row>

                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">Corporate Email</Typography></Col>
                                    <Col>
                                        <TextField value={email} fullWidth onChange={(e)=>setEmail(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    {
                                        isUpdating ? <Grid>
                                            <CircularProgress />
                                        </Grid>
                                        :
                                        <Button fullWidth color="success" variant="contained" onClick={updateCompany}>Update Company Details</Button>
                                    }
                                    </Col>
                                </Row>
                            </Container>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {
                            isLoading? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Container>
                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">MemberShip Id</Typography></Col>
                                    <Col><Typography variant="inherit">{
                                        memId.length===1 ? "INVESTOR00"+memId: memId.length===2 ? "INVESTOR0"+memId: "INVESTOR"+memId
                                    }</Typography></Col>
                                </Row>

                                <Row style={{marginTop: "10px"}}>
                                    <Col><Typography variant="inherit">MemberShip Status</Typography></Col>
                                    <Col><Typography variant="inherit">{status}</Typography></Col>
                                </Row>
                            </Container>
                        }
                    </TabPanel>
                    
                    
                </Box>
                <ToastContainer />
            </Container>

        </React.Fragment>
    )
}
