import React, {useEffect} from 'react'
import {useHistory, useParams, Link} from 'react-router-dom'
import {Grid, Avatar, Typography, Divider, TextField, CircularProgress, Button} from '@mui/material'
import {Container, Row, Col, Carousel} from 'react-bootstrap'
import db from '../../../../config/firestore'
import {doc, getDoc,getDocs, collection, setDoc, updateDoc, addDoc, serverTimestamp,deleteDoc, query, where} from 'firebase/firestore/lite'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../header/Header'
import useStyles from '../../../styles/HeaderStyles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import MaterialTable from 'material-table'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  


export default function ViewDealConnect() {
    const {id} = useParams()
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false)

    

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [applications, setApplications] = React.useState([])
    
    const fetchApplications = async()=>{
        const response = collection(db, 'DealConnects', id, "Applications");
         const dealApplications = await getDocs(response);

         dealApplications.forEach(item=>{
             
            setApplications((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
          
           
    }

    
    const history = useHistory()
    const [isLoading, setIsLoading] = React.useState(true)
    const dealRef = doc(db, "DealConnects", id);
    
    const [dealTitle, setDealTitle]= React.useState("") 
    const [dealStatus, setDealStatus]= React.useState("")
const [dealCreatorPics, setDealCreatorPics]= React.useState("")
const [AnyHotMoney, setAnyHotMoney]= React.useState("") 
const [AnyPersonalEquity, setAnyPersonalEquity]= React.useState("") 
const [ArchitecturalDesignReady, setArchitecturalDesignReady]= React.useState("")
const [BusinessPlanReady, setBusinessPlanReady]= React.useState("") 
const [CommonEquityInvestor, setCommonEquityInvestor]= React.useState("") 
const [DealCreatedBy, setDealCreatedBy] = React.useState("") 
const [DealCreatorName, setDealCreatorName] = React.useState("")
const [DealCreatorOffers, setDealCreatorOffers] = React.useState([])
const [FinancialModelReady, setFinancialModelReady] = React.useState("")
const [JointPartnersOffer, setJointPartnersOffer] = React.useState("") 
const [NetOperatingCost, setNetOperatingCost] = React.useState("")
const [NetOperatingIncome, setNetOperatingIncome]= React.useState("")
const [NumberOfInvestors, setNumberOfInvestors] = React.useState("") 
const [OverAllROI, setOverAllROI]= React.useState("")
const [PrefferedEquityInvestor, setPrefferedEquityInvestor] = React.useState("")
const [ProfitSharingRatio, setProfitSharingRatio] = React.useState("")
const [ProjectCompletionTimeLine, setProjectCompletionTimeLine] = React.useState("")
const [TypeOfJointVenture, setTypeOfJointVentures] = React.useState("")
//images

const [image1, setImage1] = React.useState("") 
const [image2, setImage2] = React.useState("") 
const [image3, setImage3] = React.useState("")

//offers

//Land

const [landSize, setLandSize] = React.useState("") 
const [landLocation, setLandLocation] = React.useState("") 
const [landPriceWorth, setLandPriceWorth] = React.useState("") 
const [titleOfDocument, setTitleOfDocument] = React.useState("")
const [structureOnLand, setStructureOnLand] = React.useState("") 
const [landFreeFromReclamation, setLandFreeFromReclamation] = React.useState("") 
const [buildingPlan, setBuldingPlan]= React.useState("") 

//Funding

const [amountOfInvestment, setAmountOfInvestment] = React.useState("")

//Investors
const [amountOfInvestmentByInvestor, setAmountOfInvestmentByInvestor] = React.useState("")
const [expectedROI, setExpectedROI] = React.useState("")

//Off-Takers

const [guranteedOffTakers, setGuranteedOffTakers] = React.useState("")

    const fetchDeals = async()=>{
        await getDoc(dealRef).then(async(deals)=>{
            if(deals.exists()){
                 setDealTitle(await deals.get("DealTitle"))
                 setDealStatus(await deals.get("DealStatus"))
                 setDealCreatorPics(await deals.get("DealCreatorPics"))
                 setAnyHotMoney(await deals.get("AnyHotMoney"))
                 setAnyPersonalEquity(await deals.get("AnyPersonalEquity"))
                 setDealCreatedBy(await deals.get("DealCreatedBy"))
                 setDealCreatorName(await deals.get("DealCreatorName"))
                 setArchitecturalDesignReady(await deals.get("ArchitecturalDesignReady"))
                 setBusinessPlanReady(await deals.get("BusinessPlanReady"))
                 setCommonEquityInvestor(await deals.get("CommonEquityInvestor"))
                 setDealCreatorOffers(await deals.get("DealCreatorOffers"))
                 setFinancialModelReady(await deals.get("FinancialModelReady"))
                 setJointPartnersOffer(await deals.get("JointPartnersOffer"))
                 setNetOperatingCost(await deals.get("NetOperatingCost"))
                 setNetOperatingIncome(await deals.get("NetOperatingIncome"))
                 setNumberOfInvestors(await deals.get("NumberOfInvestors"))
                 setOverAllROI(await deals.get("OverAllROI"))
                 setPrefferedEquityInvestor(await deals.get("PrefferedEquityInvestor"))
                 setProfitSharingRatio(await deals.get("ProfitSharingRatio"))
                 setProjectCompletionTimeLine(await deals.get("ProjectCompletionTimeLine"))
                 setTypeOfJointVentures(await deals.get("TypeOfJointVenture"))
                 setImage1(await deals.get("ProjectRelatedImages1"))
                 setImage2(await deals.get("ProjectRelatedImages2"))
                 setImage3(await deals.get("ProjectRelatedImages3"))

                  
                     //Land only

                     setLandSize(await deals.get("LandSize")) 
                     setLandLocation(await deals.get("LandLocation"))
                     setLandPriceWorth(await deals.get("LandPriceWorth"))
                     setTitleOfDocument(await deals.get("TitleOfDocument"))
                     setStructureOnLand(await deals.get("StructureOnLand"))
                     setLandFreeFromReclamation(await deals.get("FreeFromReclamation")) 
                     setBuldingPlan(await deals.get("TypeOfStructurePlan"))
                     
                     
                   
                     //Land and funding
                     setLandSize(await deals.get("LandSize")) 
                     setLandLocation(await deals.get("LandLocation"))
                     setLandPriceWorth(await deals.get("LandPriceWorth"))
                     setTitleOfDocument(await deals.get("TitleOfDocument"))
                     setStructureOnLand(await deals.get("StructureOnLand"))
                     setLandFreeFromReclamation(await deals.get("FreeFromReclamation")) 
                     setBuldingPlan(await deals.get("TypeOfStructurePlan"))
                     setAmountOfInvestment(await deals.get("AmountOfInvestment"))
         
                     
                  
                     //Land and investors
                     setLandSize(await deals.get("LandSize")) 
                     setLandLocation(await deals.get("LandLocation"))
                     setLandPriceWorth(await deals.get("LandPriceWorth"))
                     setTitleOfDocument(await deals.get("TitleOfDocument"))
                     setStructureOnLand(await deals.get("StructureOnLand"))
                     setLandFreeFromReclamation(await deals.get("FreeFromReclamation")) 
                     setBuldingPlan(await deals.get("TypeOfStructurePlan"))
                     setAmountOfInvestmentByInvestor(await deals.get("AmountOfInvestmentByInvestor"))
                     setExpectedROI(await deals.get("InvestmentExpectedROI"))
         
                     
                   
                     //Land and offtakers
                     setLandSize(await deals.get("LandSize")) 
                     setLandLocation(await deals.get("LandLocation"))
                     setLandPriceWorth(await deals.get("LandPriceWorth"))
                     setTitleOfDocument(await deals.get("TitleOfDocument"))
                     setStructureOnLand(await deals.get("StructureOnLand"))
                     setLandFreeFromReclamation(await deals.get("FreeFromReclamation")) 
                     setBuldingPlan(await deals.get("TypeOfStructurePlan"))
                     setGuranteedOffTakers(await deals.get("GuaranteedOffTakers"))
         
                     
                   
                     //only funding
                     setAmountOfInvestment(await deals.get("AmountOfInvestment"))
                     
                  
                    //funding and investors
                    setAmountOfInvestment(await deals.get("AmountOfInvestment"))
                    setAmountOfInvestmentByInvestor(await deals.get("AmountOfInvestmentByInvestor"))
                    setExpectedROI(await deals.get("InvestmentExpectedROI"))
                     
                  
                     //funding and off takers
                     setAmountOfInvestment(await deals.get("AmountOfInvestment"))
                     setGuranteedOffTakers(await deals.get("GuaranteedOffTakers"))
                     
                  
                     //Only investors
                     setAmountOfInvestmentByInvestor(await deals.get("AmountOfInvestmentByInvestor"))
                     setExpectedROI(await deals.get("InvestmentExpectedROI"))
                     
                   
                     //Investors and offtakers
                     setAmountOfInvestmentByInvestor(await deals.get("AmountOfInvestmentByInvestor"))
                     setExpectedROI(await deals.get("InvestmentExpectedROI"))
                     setGuranteedOffTakers(await deals.get("GuaranteedOffTakers"))
         
                   
                   
                    //Only offtakers
                    setGuranteedOffTakers(await deals.get("GuaranteedOffTakers"))

                    fetchApplications()
           }
           setIsLoading(false)

         }).catch((error)=>{
             
             history.push("/")
             setIsLoading(false)
         })
       

        setIsLoading(false)
    }

    useEffect(() => {
        if(currentAdmin){
            fetchDeals()
            
        }else{
            history.push("/")
        }
    }, [])

    const classes = useStyles()
    const [isOpening, setIsOpening] = React.useState(false)
    const [isClosing, setIsClosing] = React.useState(false)
    const openDeal = async()=>{
        if(department==="Communications & Strategy"){
            alert("Access Denied")
        }else{
            setIsOpening(true)
            updateDoc(doc(db,"DealConnects", id),{
                DealStatus: "Open"
            }).then(async(_)=>{
               await addDoc(collection(db, "Feeds"),{
                    DealConnectID: id,
                    FeedCreatedAt: serverTimestamp(),
                    FeedCreatedBy: DealCreatedBy,
                    FeedCreatorName: DealCreatorName,
                    FeedCreatorPics: dealCreatorPics,
                    FeedStatus: "Open",
                    FeedTitle: dealTitle,
                    FeedType: "Deal Connect"
                }).then(async(_)=>{
                    await getDoc(doc(db, "Members",DealCreatedBy)).then(async(docs)=>{
                        if(docs.exists()){
                            const email = await docs.get("EmailAddress")
                            
                          await  fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                            method: "post",
                            headers: {
                            "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                            name: DealCreatorName,
                            email: email,
                            subject: dealTitle+" is now Live on Devclub360",
                            message: "<p>This is not notify you that the above mentioned deal that you created is now live on Devclub360.</p><p>Keep using Devclub360</p><p>Devclub360 Team</p>",
                            adminEmail: "operations@devclub360.com",
                            adminSubject: dealTitle+" is now Live on Devclub360",
                            adminMessage: "<p>This is not notify you that the above mentioned deal that you created is now live on Devclub360.</p><p>Keep using Devclub360</p><p>Devclub360 Team</p>"
                            })
                        }).then(res=>res.json())
                        .then(data=>{
                            console.log(data)
                        })

                        toast.success(dealTitle+ " is now live")

                        setIsOpening(false)
                        window.location.reload()

                        }else{
                            toast.info("The Deal is now live for members but an error occured while fetching deal creator's email")
                            setIsOpening(false)
                            window.location.reload()
                        }
                    })
                })
            })
        }
    }

    const closeDeal = async()=>{
        if(department==="Communications & Strategy"){
            alert("Access Denied")
        }else{
            setIsClosing(true)
            updateDoc(doc(db,"DealConnects", id),{
                DealStatus: "Closed"
            }).then(async(_)=>{
                const q = query(collection(db, "Feeds"), where("DealConnectID", "==", id));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((docs) => {
                    if(docs.exists()){
                        deleteDoc(doc(db, "Feeds", docs.id)).then((_)=>{
                            toast.success("Deal has been marked as closed, and feeds has been removed")
                            setIsClosing(false)
                            window.location.reload()
                        })
                    }else{
                        return
                    }
                  });
                
            })
        }
    }

    const [isApproving, setIsApproving] = React.useState(false)
    

    const approveDeal = async (uid) =>{
        if(department==="Communications & Strategy"){
            alert("Access Denied")
        }else{
            setIsApproving(true)
            await updateDoc(doc(db, "DealConnects",id,"Applications",uid),{
                ApplicationStatus: "Approved"
            }).then(async(_)=>{
                await getDoc(doc(db, "Members",DealCreatedBy)).then(async(docs)=>{
                    const email = await docs.get("EmailAddress")

                   await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                            method: "post",
                            headers: {
                            "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                            name: DealCreatorName,
                            email: email,
                            subject: "Approval of "+dealTitle+" Application",
                            message: "<p>This is to inform you that your application for "+dealTitle+" has been approved.</p><p> Meeting Details will be forwarded to you as soon as its available</p>. <p>Keep Uaing Devclub360</p>",
                            adminEmail: "operations@devclub360.com",
                            adminSubject: "Approval of "+dealTitle+" Application for"+email,
                            adminMessage: "<p>This is to inform you that your application for "+dealTitle+" has been approved.</p><p> Meeting Details will be forwarded to you as soon as its available</p>. <p>Keep Using Devclub360</p>"
                            })
                        }).then(res=>res.json())
                        .then(data=>{
                            console.log(data)
                        }).catch(error=>{
                            toast.error(error)
                        })
                        setIsApproving(false)
                        toast.success("The member application has been approved successfully")
                        window.location.reload()

                })

                
            })
        }
    }

    const disApproveDeal = async (uid) =>{
        if(department==="Communications & Strategy"){
            alert("Access Denied")
        }else{
            setIsApproving(true)
            await updateDoc(doc(db, "DealConnects",id,"Applications",uid),{
                ApplicationStatus: "Cancelled"
            }).then(async(_)=>{
                await getDoc(doc(db, "Members",DealCreatedBy)).then(async(docs)=>{
                    const email = await docs.get("EmailAddress")

                   await fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                            method: "post",
                            headers: {
                            "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                            name: DealCreatorName,
                            email: email,
                            subject: "Cancellation/Disapproval of "+dealTitle+" Application",
                            message: "<p>This is to inform you that your application for "+dealTitle+" has been rejected or cancelled.</p><p> This may be because you requested for cancellation or disqualification policies. You can contact Devclub360 via In-App Messaging for further discussion</p>. <p>Keep Uaing Devclub360</p>",
                            adminEmail: "operations@devclub360.com",
                            adminSubject: "Cancellation/Disapproval of "+dealTitle+" Application for "+email,
                            adminMessage: "<p>This is to inform you that your application for "+dealTitle+" has been rejected or cancelled.</p><p> This may be because you requested for cancellation or disqualification policies. You can contact Devclub360 via In-App Messaging for further discussion</p>. <p>Keep Using Devclub360</p>",
                            })
                        }).then(res=>res.json())
                        .then(data=>{
                            console.log(data)
                        }).catch(error=>{
                            toast.error(error)
                        })
                        setIsApproving(false)
                        toast.success("The member application has been cancelled/revoked successfully")
                        window.location.reload()

                })

                
            })
        }
    }

    
    
    return (
        <React.Fragment>
            <Header />
        <Container className={classes.wrapper}>
            <Box sx={{ width: '100%' }}>
                {
                    isLoading ?

                    <Grid align="center">
                        <CircularProgress />
                    </Grid>

                    :
                <Grid>    
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Deal Connect Information" {...a11yProps(0)} />
                        <Tab label="Applications/Interests" {...a11yProps(1)} />
                        
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                    <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Project Related Images</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Project Related Images
                    </DialogContentText>
                    <Carousel>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={image1}
                        alt=""
                        height="100%"
                        width="100%"
                      />

                      <Carousel.Caption>
                      <h3>First Related Image</h3>
                      <p>First Image on the List.</p>
                    </Carousel.Caption>
                      
                    </Carousel.Item>

                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={image2}
                        alt=""
                        height="100%"
                        width="100%"
                      />

                  <Carousel.Caption>
                    <h3>Second Related Image</h3>
                    <p>Second Image on the List.</p>
                  </Carousel.Caption>
                      
                    </Carousel.Item>

                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={image3}
                        alt=""
                        height="100%"
                        width="100%"
                      />

                  <Carousel.Caption>
                    <h3>Third Related Image</h3>
                    <p>Last Image on the List.</p>
                  </Carousel.Caption>
                      
                    </Carousel.Item>
                    
                </Carousel>
                  </DialogContent>
                  <DialogActions>
                    
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </Dialog> 
                  
                    <Container>
                        <Row align="center" style={{marginBottom: "24px"}}>
                            <Col>
                                <Avatar src={dealCreatorPics} alt ="profile" sx={{ width: 120, height: 120 }} />
                                   
                            </Col>
                           <Link style={{textDecoration: "none"}} to={"/dashboard/members/details/"+DealCreatedBy}> <Button variant="text"><Typography variant="inherit">Tap View Creator's Profile</Typography></Button></Link>
                        </Row>
                        <Typography variant="h6" align="left">Project Information</Typography>
                        <Divider />
                        <Grid align="center"> 
                          <Button variant="text" onClick={()=>handleClickOpen()}><Typography variant="inherit">Tap to view project related images</Typography></Button>
                        </Grid>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Deal Title</Typography></Col>
                                        <Col><Typography variant="inherit">{dealTitle}</Typography></Col>
                                      </Row>
                                      
                                    </TableCell>
                                   
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Deal Status</Typography></Col>
                                        <Col><Typography variant="inherit">{dealStatus}</Typography></Col>
                                      </Row>
                                      
                                    </TableCell>
                                   
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Name of Creator</Typography></Col>
                                        <Col><Typography variant="inherit">{DealCreatorName}</Typography></Col>
                                      </Row>
                                      
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Any Hot Money</Typography></Col>
                                        <Col><Typography variant="inherit">{AnyHotMoney}</Typography></Col>
                                      </Row>
                                      
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Personal Equity</Typography></Col>
                                        <Col><Typography variant="inherit">{AnyPersonalEquity}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Architectural Design Ready</Typography></Col>
                                        <Col><Typography variant="inherit">{ArchitecturalDesignReady}</Typography></Col>
                                      </Row>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Business Plan Ready</Typography></Col>
                                        <Col><Typography variant="inherit">{BusinessPlanReady}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Common Equity Investor</Typography></Col>
                                        <Col><Typography variant="inherit">{CommonEquityInvestor}</Typography></Col>
                                      </Row>
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Financial Model Ready</Typography></Col>
                                        <Col><Typography variant="inherit">{FinancialModelReady}</Typography></Col>
                                      </Row>

                                    
                                    </TableCell>
                                   
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">What this creator requires from prospective partners/investors?</Typography></Col>
                                        <Col> <Typography variant="inherit">{JointPartnersOffer}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                          <Col><Typography variant="inherit">The type(s) of Investor/Partners this creator is interested in?</Typography></Col>
                                          <Col><Typography variant="inherit">{TypeOfJointVenture}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                        <Row>
                                          <Col><Typography variant="inherit">Net Operating Cost</Typography></Col>
                                          <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(NetOperatingCost))}</Typography></Col>
                                        </Row>
                                    
                                    </TableCell>
                                  
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Net Operating Income</Typography></Col>
                                        <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(NetOperatingIncome))}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>
                                  
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Number Of Investors required</Typography></Col>
                                        <Col><Typography variant="inherit">{NumberOfInvestors}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Over All ROI</Typography></Col>
                                        <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(OverAllROI))}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                  
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Preffered Equity Investor</Typography></Col>
                                        <Col><Typography variant="inherit">{PrefferedEquityInvestor}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Profit Sharing Ratio</Typography></Col>
                                        <Col><Typography variant="inherit">{ProfitSharingRatio}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Project Completion TimeLine</Typography></Col>
                                        <Col><Typography variant="inherit">{ProjectCompletionTimeLine}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                      <Row style={{marginTop: "19px"}} align="center">
                        <Col><Typography variant="h6" align="left">Project Offers</Typography></Col>
                      </Row>
                      <Divider />
                    </Container>
                    <TableContainer  style={{marginTop: "19px"}}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Row>
                                <Col><Typography>Project Creator Offers</Typography></Col>
                                <Col>{
                                DealCreatorOffers.map(item=>{
                                  return <Typography>{item}</Typography>
                                })
                              }</Col>
                              </Row>
                              
                            </TableCell>
                           
                          </TableRow>
                          {
                            DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            ? <Grid>
                              <TableRow>
                                <TableCell><Typography>Land Size</Typography></TableCell>
                                <TableCell><Typography>{landSize}</Typography></TableCell>

                            </TableRow> 
                            <TableRow>
                              <TableCell><Typography>Land Location</Typography></TableCell>
                              <TableCell><Typography>{landLocation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Land Price Worth</Typography></TableCell>
                              <TableCell><Typography>{landPriceWorth}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Title of Document</Typography></TableCell>
                              <TableCell><Typography>{titleOfDocument}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Structure on Land</Typography></TableCell>
                              <TableCell><Typography>{structureOnLand}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Land From Reclamation?</Typography></TableCell>
                              <TableCell><Typography>{landFreeFromReclamation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Type Of Structure wanted on Land</Typography></TableCell>
                              <TableCell><Typography>{buildingPlan}</Typography></TableCell>

                            </TableRow>
                            </Grid>
                            
                            
                            : DealCreatorOffers.toString().includes("Land") && DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers") 
                            ? 
                            <TableBody>
                                <TableRow>
                                <TableCell><Typography>Land Size</Typography></TableCell>
                                <TableCell><Typography>{landSize}</Typography></TableCell>

                            </TableRow> 
                            <TableRow>
                              <TableCell><Typography>Land Location</Typography></TableCell>
                              <TableCell><Typography>{landLocation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Land Price Worth</Typography></TableCell>
                              <TableCell><Typography>{landPriceWorth}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Title of Document</Typography></TableCell>
                              <TableCell><Typography>{titleOfDocument}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Structure on Land</Typography></TableCell>
                              <TableCell><Typography>{structureOnLand}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Land Free from Reclamation</Typography></TableCell>
                              <TableCell><Typography>{landFreeFromReclamation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Type Of Structure wanted on Land</Typography></TableCell>
                              <TableCell><Typography>{buildingPlan}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                            <TableCell><Typography>Amount of Investment</Typography></TableCell>
                              <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestment))}</Typography></TableCell>
                            </TableRow>
                            </TableBody>
                            :  DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            ? 
                            <TableBody>
                              <TableRow>
                                <TableCell><Typography>Land Size</Typography></TableCell>
                                <TableCell><Typography>{landSize}</Typography></TableCell>

                            </TableRow> 
                            <TableRow>
                              <TableCell><Typography>Land Location</Typography></TableCell>
                              <TableCell><Typography>{landLocation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Land Price Worth</Typography></TableCell>
                              <TableCell><Typography>{landPriceWorth}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Title of Document</Typography></TableCell>
                              <TableCell><Typography>{titleOfDocument}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Structure on Land</Typography></TableCell>
                              <TableCell><Typography>{structureOnLand}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Land Free from Reclamation</Typography></TableCell>
                              <TableCell><Typography>{landFreeFromReclamation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Type Of Structure wanted on Land</Typography></TableCell>
                              <TableCell><Typography>{buildingPlan}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography variant="inherit">Amount of Investment by Investor</Typography></TableCell>
                              <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestmentByInvestor))}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Expected return On Investment</Typography></TableCell>
                              <TableCell><Typography>{expectedROI}</Typography></TableCell>

                            </TableRow>

                            </TableBody> 
                            : DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && DealCreatorOffers.toString().includes("Off-Takers")
                            ?
                            <Grid>
                              <TableRow>
                                <TableCell><Typography>Land Size</Typography></TableCell>
                                <TableCell><Typography>{landSize}</Typography></TableCell>

                            </TableRow> 
                            <TableRow>
                              <TableCell><Typography>Land Location</Typography></TableCell>
                              <TableCell><Typography>{landLocation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Land Price Worth</Typography></TableCell>
                              <TableCell><Typography>{landPriceWorth}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Title of Document</Typography></TableCell>
                              <TableCell><Typography>{titleOfDocument}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Structure on Land</Typography></TableCell>
                              <TableCell><Typography>{structureOnLand}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Land Free from Reclamation</Typography></TableCell>
                              <TableCell><Typography>{landFreeFromReclamation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Type Of Structure wanted on Land</Typography></TableCell>
                              <TableCell><Typography>{buildingPlan}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography variant="inherit">Guranteed Off-Takers?</Typography></TableCell>
                              <TableCell><Typography>{guranteedOffTakers}</Typography></TableCell>
                            </TableRow>
                            </Grid>
                            : 
                            !DealCreatorOffers.toString().includes("Land") && DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            
                            ? <Grid>
                              <TableRow>
                              <TableCell><Typography>Amount of Investment</Typography></TableCell>
                                <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestment))}</Typography></TableCell>
                              </TableRow>
                            </Grid>
                            :
                            !DealCreatorOffers.toString().includes("Land") && DealCreatorOffers.toString().includes("Funding") && DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            ? 
                            <Grid>
                                 <TableRow>
                                  <TableCell><Typography>Amount of Investment</Typography></TableCell>
                                  <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestment))}</Typography></TableCell>
                              </TableRow>

                              <TableRow>
                              <TableCell><Typography variant="inherit">Amount of Investment by Investor</Typography></TableCell>
                              <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestmentByInvestor))}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Expected return On Investment</Typography></TableCell>
                              <TableCell><Typography>{expectedROI}</Typography></TableCell>

                            </TableRow>
                            </Grid> 
                            :
                            !DealCreatorOffers.toString().includes("Land") && DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && DealCreatorOffers.toString().includes("Off-Takers")

                            ?
                            <Grid>
                               <TableRow>
                                  <TableCell><Typography>Amount of Investment</Typography></TableCell>
                                  <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestment))}</Typography></TableCell>
                              </TableRow>
                              <TableRow>
                              <TableCell><Typography variant="inherit">Guranteed Off-Takers?</Typography></TableCell>
                              <TableCell><Typography>{guranteedOffTakers}</Typography></TableCell>
                            </TableRow>
                            </Grid>

                            :
                            !DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            ?
                             <Grid>
                                <TableRow>
                                <TableCell><Typography variant="inherit">Amount of Investment by Investor</Typography></TableCell>
                                <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestmentByInvestor))}</Typography></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell><Typography>Expected return On Investment</Typography></TableCell>
                                <TableCell><Typography>{expectedROI}</Typography></TableCell>

                              </TableRow>
                            </Grid> 
                            :
                            !DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && DealCreatorOffers.toString().includes("Investors") && DealCreatorOffers.toString().includes("Off-Takers")
                            ? 
                            <Grid>
                                <TableRow>
                                <TableCell><Typography variant="inherit">Amount of Investment by Investor</Typography></TableCell>
                                <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestmentByInvestor))}</Typography></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell><Typography>Expected return On Investment</Typography></TableCell>
                                <TableCell><Typography>{expectedROI}</Typography></TableCell>

                              </TableRow>

                              <TableRow>
                              <TableCell><Typography variant="inherit">Guranteed Off-Takers?</Typography></TableCell>
                              <TableCell><Typography>{guranteedOffTakers}</Typography></TableCell>
                            </TableRow>
                            </Grid>:
                            !DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && DealCreatorOffers.toString().includes("Off-Takers")
                            
                            ?
                            <Grid>
                                <TableRow>
                                  <TableCell><Typography variant="inherit">Guranteed Off-Takers?</Typography></TableCell>
                                  <TableCell><Typography>{guranteedOffTakers}</Typography></TableCell>
                                </TableRow>
                            </Grid>: <Typography variant="h6">No Offer found</Typography>
                          }
                        </TableBody> 
                      </Table>
                    </TableContainer>
                    <Grid style={{marginTop:"10px"}}>
                          {
                              dealStatus==="Open" ?

                             isClosing? <CircularProgress />
                             :<Button onClick={closeDeal} variant="contained" color="primary" fullWidth>Close Deal</Button>

                              :
                              isOpening? <CircularProgress />
                              :
                              <Button onClick={openDeal} variant="contained" color="primary" fullWidth>Open Deal</Button>
                          }
                    </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {
                            applications.length === 0 ?
                            <Grid align="center">
                                <Typography variant="h6">No Applications found for this deal</Typography>
                            </Grid>

                            :

                            <Grid>
                                <Typography variant="h6" align="center">Number of Applications: {applications.length}</Typography>
                                <MaterialTable
                                    style={{marginTop: "10px"}}
                                    title= {dealTitle+ " Applications"}
                                    columns= {[
                                        
                                        {
                                            title: 'Deal Applicator',
                                            field: 'ApplicatorID',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <Link to={"/dashboard/members/details/"+rowData.ApplicatorID} style={{textDecoration: "none"}} target="_blank">View Member's Profile</Link>
                                                
                                              </>

                                        },
                                        {
                                            title: 'Project Offer',
                                            field: 'ProjectOffer'
                                        },
                                        {
                                            title: 'Application Status',
                                            field: 'ApplicationStatus'
                                        },
                                        {
                                            title: 'Date of Application',
                                            field: 'JoinedAt',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                {new Date(rowData.JoinedAt * 1000).getDate()+
                                                    "/"+(new Date(rowData.JoinedAt * 1000).getMonth()+1)+
                                                    " "+new Date(rowData.JoinedAt * 1000).getHours()+
                                                    ":"+new Date(rowData.JoinedAt * 1000).getMinutes()+
                                                    ":"+new Date(rowData.JoinedAt * 1000).getSeconds()
                                                }
                                                
                                              </>
                                        },
                                        
                                        {
                                            title: 'Approve/Disapprove',
                                            field: 'approval',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    {
                                                        isApproving? <CircularProgress />
                                                        :
                                                        rowData.ApplicationStatus === "Approved" ?
                                                        <Button variant="contained" fullWidth color="primary" onClick={()=>disApproveDeal(rowData.ApplicatorID)}>Disapprove</Button>
                                                        :
                                                        <Button variant="contained" fullWidth color="primary" onClick={()=>approveDeal(rowData.ApplicatorID)}>Approve</Button>
                                                    }
                                                
                                              </>
                                        },
                                        
                                        
                                    ]}
                                    data= {applications}
                                    
                                    options={{
                                        pageSize: 10,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </TabPanel>
                </Grid>

                }
                <ToastContainer />
            </Box>
        </Container>
        </React.Fragment>
    )
}
