import React,{useEffect} from 'react'
import useStyles from '../../../styles/HeaderStyles'
import {Container, Row, Col, Card} from 'react-bootstrap'
import {Grid, Typography, Divider, CircularProgress} from '@mui/material'
import {Link} from 'react-router-dom'
import db from '../../../../config/firestore'
import { collection, getDocs } from "firebase/firestore/lite"
import {useHistory} from 'react-router-dom'


export default function Overview() {

    const history = useHistory()
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const [isLoading, setIsLoading] = React.useState(true);
    const [members, setMembers] = React.useState([])
    const [staffs, setStaffs] = React.useState([])
    const [payments, setPayments] = React.useState([])
    const [dealShare, setDealShare] = React.useState([])
    const [dealConnect, setDealConnect] = React.useState([])
    const [transaction, setTransactions] = React.useState([])

    const fetchMembers= async()=>{
        
        const response = collection(db, 'Members');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setMembers((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
          
      }

      const fetchStaffs= async()=>{
        
        const response = collection(db, 'Staffs');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setStaffs((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
          
      }

      const fetchPayments= async()=>{
        
        const response = collection(db, 'MembershipPayments');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setPayments((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
          
      }

      const fetchDealShare= async()=>{
        
        const response = collection(db, 'DealShares');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setDealShare((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
          
      }

      const fetchDealConnect= async()=>{
        
        const response = collection(db, 'DealConnects');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setDealConnect((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
          
      }

      const fetchTransactions= async()=>{
        
        const response = collection(db, 'MembersTransactions');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setTransactions((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
          

      }


      useEffect(async() => {
          if(currentAdmin){
                await fetchMembers()
                await fetchStaffs()
                await fetchPayments()
                await fetchDealShare()
                await fetchDealConnect()
                await fetchTransactions()

                setIsLoading(false)
          }else{
              history.push("/")
          }
      }, [])

    const classes = useStyles()
    return (
        <React.Fragment>
            <Container className={classes.wrapper}>
                <Row>
                    <Col>
                        <Grid>
                            <Typography variant="h4">Dashboard</Typography>
                            <Divider style={{width: "100%"}} />
                        </Grid>
                    </Col>
                </Row>
                <Row style={{marginTop: "12px"}}>
                    <Col>
                        <Card style={{ width: '18rem', color: "white" }} bg="success">
                            <Card.Body>
                                <Card.Title>No. of Available Members</Card.Title>
                                
                                <Card.Text>
                                {isLoading ? <CircularProgress />: members.length}
                                </Card.Text>
                                <Card.Link style={{textDecoration: "none", color:"white"}}><Link to="/dashboard/members/all" style={{textDecoration: "none", color:"white"}}>View Details</Link></Card.Link>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    <Card style={{ width: '18rem', color: "white" }} bg="primary">
                            <Card.Body>
                                <Card.Title>No. of Available Staff</Card.Title>
                                
                                <Card.Text>
                                {isLoading ? <CircularProgress />: staffs.length}
                                </Card.Text>
                                <Card.Link style={{textDecoration: "none", color:"white"}}><Link to="/dashboard/staffs/all" style={{textDecoration: "none", color:"white"}}>View Details</Link></Card.Link>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    <Card style={{ width: '18rem', color: "white" }} bg="secondary">
                            <Card.Body>
                                <Card.Title>No. of Payment received</Card.Title>
                                
                                <Card.Text>
                                {isLoading ? <CircularProgress />: payments.length}
                                </Card.Text>
                                <Card.Link style={{textDecoration: "none", color:"white"}}><Link to="/dashboard/payments" style={{textDecoration: "none", color:"white"}}>View Details</Link></Card.Link>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row style={{marginTop: "12px"}}>
                    <Col>
                        <Card style={{ width: '18rem', color: "white" }} bg="warning">
                            <Card.Body>
                                <Card.Title>No. of Deal Connects</Card.Title>
                                
                                <Card.Text>
                                {isLoading ? <CircularProgress />: dealConnect.length}
                                </Card.Text>
                                <Card.Link style={{textDecoration: "none", color:"white"}}><Link to="/dashboard/deal-connect/all" style={{textDecoration: "none", color:"white"}}>View Details</Link></Card.Link>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    <Card style={{ width: '18rem', color: "white" }} bg="info">
                            <Card.Body>
                                <Card.Title>No. of Deal Share</Card.Title>
                                
                                <Card.Text>
                                {isLoading ? <CircularProgress />: dealShare.length}
                                </Card.Text>
                                <Card.Link style={{textDecoration: "none", color:"white"}}><Link to="/dashboard/deal-share/all" style={{textDecoration: "none", color:"white"}}>View Details</Link></Card.Link>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    <Card style={{ width: '18rem', color: "white" }} bg="danger">
                            <Card.Body>
                                <Card.Title>No. of Transactions</Card.Title>
                                
                                <Card.Text>
                                {isLoading ? <CircularProgress />: transaction.length}
                                </Card.Text>
                                <Card.Link style={{textDecoration: "none", color:"white"}}><Link to="/dashboard/transactions" style={{textDecoration: "none", color:"white"}}>View Details</Link></Card.Link>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
