import React, {useEffect} from 'react'
import db from '../../../../config/firestore'
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, Typography, CircularProgress, Paper, IconButton, TextField, Button} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialTable from 'material-table'
import { collection, getDocs, doc, query, where, updateDoc, deleteDoc } from "firebase/firestore/lite"
import {useHistory, Link} from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function QuickPostTable(){

    const history = useHistory()
    const currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"))
    const department = localStorage.getItem("adminDepartment")
    const [posts, setPosts] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    const fetchPosts= async()=>{
        
        const response = collection(db, 'QuickPosts');
        const data= await getDocs(response);

        data.forEach(item=>{
            
            setPosts((prevState)=>{
              
                return [...prevState, item.data()]
            })
           })
          setIsLoading(false)
      }

      useEffect(() => {
          if(currentAdmin){
              fetchPosts()
          }else{
              history.push("/")
          }
      }, [])
    
      const paperStyle = {
        padding: 20,
        height: '15vh',
        width: "100%",
        margin: "20px auto"
    }

    const viewPosts = async(id, content)=>{
        if(id==="" || id===undefined){
            const q = query(collection(db, "QuickPosts"), where("Posts", "==", content));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((data) => {
                
                    updateDoc(doc(db, "QuickPosts", data.id),{
                        QuickPostId: data.id
                    }).then((_)=>{
                        history.push("/dashboard/quick-posts/details/"+data.id)
                    })
                
            });
        }else{
            history.push("/dashboard/quick-posts/details/"+id)
        }
 
    }

    const [open, setOpen] = React.useState(false);
    const [editPost, setEditPost] = React.useState("")
    const [uid, setUid] = React.useState("")
    const handleClickOpen = async(id, content) => {
        if(department ==="Communications & Strategy"){
            alert("Access denied")
        }else{
            if(id==="" || id===undefined){
                const q = query(collection(db, "QuickPosts"), where("Posts", "==", content));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((data) => {
                    
                        updateDoc(doc(db, "QuickPosts", data.id),{
                            QuickPostId: data.id
                        }).then((_)=>{
                           setEditPost(content)
                           setUid(data.id)
                           setOpen(true)
                        })
                    
                });
            }else{
                setEditPost(content)
                setUid(id)
                setOpen(true)
            }
        }
        
    };

    const handleClose = () => {
        setOpen(false);
    };

    const editMemPost = async()=>{
        await updateDoc(doc(db, "QuickPosts", uid),{
            Posts: editPost
        }).then((_)=>{
            handleClose()
            toast.success("Post has been edited successfully")
            setPosts([])
            setIsLoading(true)
            fetchPosts()
        })
    }

    const deletePost = async(id, content) =>{
        if(department==="Communications & Strategy"){
            alert("Access Denied")
        }else{
            if(id==="" || id===undefined){
                const q = query(collection(db, "QuickPosts"), where("Posts", "==", content));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((data) => {
                    
                        updateDoc(doc(db, "QuickPosts", data.id),{
                            QuickPostId: data.id
                        }).then(async(_)=>{
                           if(window.confirm("Are You sure you want to delete this post?")){
                               deleteDoc(doc(db, "QuickPosts", data.id)).then(async(_)=>{
                                   const f = query(collection(db, "Feeds"), where("QuickPostId","==",data.id));
                                   const sn = await getDocs(f);
                                   sn.forEach((feed)=>{
                                       deleteDoc(doc(db, "Feeds", feed.id)).then((_)=>{
                                        toast.success("Post has been deleted successfully")
                                        setIsLoading(true)
                                        setPosts([])
                                        fetchPosts()
                                       })
                                   })
                               })
                           }else{
                               return
                           }
                        })
                    
                });
            }else{
                if(window.confirm("Are You sure you want to delete this post?")){
                    deleteDoc(doc(db, "QuickPosts", id)).then(async(_)=>{
                        const f = query(collection(db, "Feeds"), where("QuickPostId","==",id));
                        const sn = await getDocs(f);
                        sn.forEach((feed)=>{
                            deleteDoc(doc(db, "Feeds", feed.id)).then((_)=>{
                             toast.success("Post has been deleted successfully")
                             setIsLoading(true)
                             setPosts([])
                             fetchPosts()
                            })
                        })
                    })
                }else{
                    return
                }
            }
        }
    }
    return (
        <React.Fragment>
            <Container>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit Post</DialogTitle>
                    <DialogContent>
                    <TextField
                        autoFocus
                        id="post"
                        label="Quick Post"
                        multiline
                        rows={6}
                        fullWidth
                        value={editPost}
                        onChangeCapture={(e)=>setEditPost(e.target.value)}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={editMemPost}>Edit Quick Post</Button>
                    </DialogActions>
                </Dialog>
            <Row style={{marginTop:"15px"}}>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :

                            posts.length === 0 ? 

                            <Grid align="center">
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography variant="h6">No Quick Post Available yet</Typography>
                                </Paper>
                            </Grid>
                            :
                            <Grid>
                                <MaterialTable
                                    title= "Devclub360 Posts"
                                    columns= {[
                                        
                                        {
                                            title: 'Profile Pics',
                                            field: 'PostedByProfilePics',
                                            render: rowData => (
                                                <img
                                                  src={rowData.PostedByProfilePics}
                                                  alt="profile"
                                                  style={{ width: 40, borderRadius: "50%" }}
                                                />
                                              )
                                        },
                                        {
                                            title: 'Name',
                                            field: 'PostedByName',
                                            render: rowData => (
                                                <Link to={"/dashboard/members/details/"+rowData.PostedByID}>{rowData.PostedByName}</Link>
                                              )
                                        },
                                        {
                                            title: 'Posts',
                                            field: 'Posts'
                                        },
                                        {
                                            title: 'Date of Creation',
                                            field: 'createdAt',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                {new Date(rowData.createdAt * 1000).getDate()+
                                                    "/"+(new Date(rowData.createdAt * 1000).getMonth()+1)+
                                                    " "+new Date(rowData.createdAt * 1000).getHours()+
                                                    ":"+new Date(rowData.createdAt * 1000).getMinutes()+
                                                    ":"+new Date(rowData.createdAt * 1000).getSeconds()
                                                }
                                                
                                              </>
                                        },
                                        {
                                            title: 'view',
                                            field: 'view',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>viewPosts(rowData.QuickPostId, rowData.Posts)}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                               
                                              </>
                                        },

                                        {
                                            title: 'Edit',
                                            field: 'edit',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>handleClickOpen(rowData.QuickPostId, rowData.Posts)}>
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                               
                                              </>
                                        },
                                        
                                        {
                                            title: 'Delete',
                                            field: 'delete',
                                            render: (rowData)  => rowData && 
                                            <>
                                                
                                                    <IconButton onClick={()=>deletePost(rowData.QuickPostId, rowData.Posts)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                
                                              </>
                                        },
                                        
                                        
                                        
                                        
                                    ]}
                                    data= {posts}
                                    
                                    options={{
                                        pageSize: 10,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                            </Grid>
                        }
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </React.Fragment>
    )
}