import React from 'react'
import {Button, Grid} from '@material-ui/core';
import AddIcon  from '@material-ui/icons/Add';
import useStyles from '../../../styles/HeaderStyles';


export default function AddNewTransaction() {
    const classes = useStyles()
    return (
        <div>
            <Grid className= {classes.dealconnect}>
                            <div style= {{textAlign: 'right'}}>
                                    <Button 
                                        variant= "contained" 
                                        color="primary"
                                        size="large"
                                        className= {classes.button}
                                        startIcon ={<AddIcon />}
                                        
                                        >
                                            Add New Transaction
                                    </Button>
                                    
                                </div>
                                
                            </Grid>

        </div>
    )
}
