import React from 'react'
import Header from '../../header/Header'
import db from '../../../../config/firestore'
import {Container, Row, Col} from 'react-bootstrap'
import {Grid, TextField, Button, Typography, Divider, CircularProgress} from '@mui/material'
import useStyles from '../../../styles/HeaderStyles'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {collection, addDoc, updateDoc, serverTimestamp, doc} from 'firebase/firestore/lite'
import {useHistory} from 'react-router-dom'

export default function CreateNewDealShare() {

    const history = useHistory()
    const [dealTitle, setDealTitle] = React.useState("")
    const [noOfInvestors, setNoOfInvestors] = React.useState("")
    const [dealDescription, setDealDescription] = React.useState("")
    const [proposal, setProposal] = React.useState()
    const [projectAmount, setProjectAmount] = React.useState("")
    const [roiCycle, setROICycle] = React.useState("")
    const [interestRate, setInterestRate] = React.useState("")
    const [minimumAmount, setMinimumAmount] = React.useState("")
    const [tps, setTPS] = React.useState("")
    const [completionTime, setCompletionTime] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const createDeal = async()=>{
        if(!dealTitle || ! noOfInvestors || !dealDescription || !proposal || !projectAmount || !roiCycle || !interestRate || !minimumAmount || !tps || !completionTime){
            toast.error("All fields are required")
        }else if(!proposal.name.includes(".pdf")){
            toast.error("Only pdf file is required")
        }else{
            setIsLoading(true)
            const data = new FormData()
            data.append("file", proposal)
            data.append("upload_preset", "devclub360")
            data.append("cloud_name", "dnnbqnnnl")

           await fetch("https://api.cloudinary.com/v1_1/dnnbqnnnl/image/upload",{
            method: "post",
            body: data
            }).then(res=>res.json())
            .then(data=>{
                addDoc(collection(db,"DealShares"),{
                    DealTitle: dealTitle,
                    NumberOfApplication: "0",
                    NumberOfInvestors: noOfInvestors.toString(),
                    DealDescription: dealDescription,
                    DealStatus: "Open",
                    ProposalUrl: data.secure_url,
                    ProjectAmount: projectAmount,
                    ROICycle: roiCycle,
                    InterestRateDistribution: interestRate,
                    MinimumAmount: minimumAmount,
                    TotalProfitShare: tps,
                    ProjectCompletionTime: completionTime,
                    createdAt: serverTimestamp()
                }).then((value)=>{
                    updateDoc(doc(db, "DealShares", value.id),{
                        DealShareId: value.id
                    }).then(async(_)=>{
                        addDoc(collection(db, "Feeds"),{
                            DealConnectID: value.id,
                            FeedCreatedAt: serverTimestamp(),
                            FeedCreatedBy: "ddlawCUSRxSirdnLHmeBlSe3WNN2",
                            FeedCreatorName: "Devclub360 Admin",
                            FeedCreatorPics: "https://res.cloudinary.com/dnnbqnnnl/image/upload/v1641047761/devclub_icon_gmuonj.png",
                            FeedStatus: "Open",
                            FeedTitle: dealTitle,
                            FeedType: "Deal Share"
                        }).then((_)=>{
                            toast.success("Deal successfully created and has gone live")
                            setIsLoading(false)
                            history.push("/dashboard/deal-share/all")
                        })
                    })
                })
            })
            .catch(err=>{
                
                    toast.error("An error was encountered while saving your data, please refresh the page and try again", {
                    position: toast.POSITION.TOP_RIGHT
                    });
            })
            
        }
    }
    const classes = useStyles()
    return (
        <React.Fragment>
            <Header />
            <Container className={classes.wrapper}>
                <Grid style={{marginBottom: "25px"}}>
                    <Typography variant="h4">Create New Deal Share</Typography>
                    <Divider />
                </Grid>
                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            value={dealTitle}
                            onChange={(e)=>setDealTitle(e.target.value)}
                            label='Deal Share Project Title'
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>
                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            type="number"
                            value={noOfInvestors}
                            onChange={(e)=>setNoOfInvestors(e.target.value)}
                            placeholder='Number of Investors required'
                            label="Number of Investors required"
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>

                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            multiline
                            rows={5}
                            value={dealDescription}
                            onChange={(e)=>setDealDescription(e.target.value)}
                            placeholder='Project Description'
                            label="Project Description"
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>

                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            type="file"
                            onChange={(e)=>setProposal(e.target.files[0])}
                            label='Upload Proposal'
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>

                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            value={projectAmount}
                            type="number"
                            onChange={(e)=>setProjectAmount(e.target.value)}
                            placeholder='Amount required for this project'
                            label="Project Amount"
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>
                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            value={roiCycle}
                            onChange={(e)=>setROICycle(e.target.value)}
                            placeholder='Return on Investment Cycle'
                            label="Return on Investment Cycle"
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>
                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            value={interestRate}
                            onChange={(e)=>setInterestRate(e.target.value)}
                            placeholder='Interest Rate Distribution'
                            label="Interest Rate Distribution"
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>
                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            value={minimumAmount}
                            type="number"
                            onChange={(e)=>setMinimumAmount(e.target.value)}
                            placeholder='Minimum Amount Required by Investor'
                            label="Minimum Amount required"
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>

                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            value={tps}
                            onChange={(e)=>setTPS(e.target.value)}
                            placeholder='Total Profit Share'
                            label="Total Profit Share"
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>

                <Row>
                    
                    <Col>
                        <TextField
                            fullWidth
                            value={completionTime}
                            onChange={(e)=>setCompletionTime(e.target.value)}
                            placeholder='Project Completion Time'
                            label="Project Completion Time"
                            style={{marginBottom: "10px"}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            isLoading ? 
                            <Grid align="center">
                                <CircularProgress />
                            </Grid>
                            :
                            <Button fullWidth color="success" variant="contained" onClick={createDeal}>Create New Deal</Button>
                        }
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </React.Fragment>
    )
}
